import numeral from "numeral";
// import { GridHeader } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import Tooltip from "@mui/material/Tooltip";

export const activeSubColumns = [
  {
    field: "numb",
    headerName: "numb",
    width: 130,
  },

  {
    field: "message",
    headerName: "message",
    width: 140,
  },
  {
    field: "serverdate",
    headerName: "serverdate",
    width: 120,
  },
  {
    field: "servertime",
    headerName: "servertime",
    width: 120,
  },
  // {
  //     field: 'created_at',
  //     headerName: 'Created',
  //     width: 200,
  //     sortable: false,
  //     renderCell: (params) =>{
  //       return (
  //           <p>
  //               {((params.row.created_at).replace('.000Z', ' ').replace('T', ' '))}
  //           </p>
  //       )
  //   }
  //   },
];
export const postedColumns = [
  {
    field: "numb",
    headerName: "numb",
    width: 130,
  },
  {
    field: "clickid",
    headerName: "Click Id",
    width: 140,
  },
  {
    field: "networkname",
    headerName: "Network Name",
    width: 120,
  },
  {
    field: "date",
    headerName: "Date",
    width: 120,
  },
  {
    field: "time",
    headerName: "Time",
    width: 120,
  },
];

export const stickyNoteNotificationsColumns = [
  {
    field: "phoneNumber",
    headerName: "numb",
    width: 120,
  },
  {
    field: "ChannelId",
    headerName: "Channel ID",
    width: 120,
  },
  {
    field: "OperatorID",
    headerName: "Operator ID",
    width: 100,
  },
  {
    field: "Status",
    headerName: "Status",
    width: 90,
  },
  {
    field: "price",
    headerName: "Price",
    width: 60,
  },
  {
    field: "date",
    headerName: "date",
    width: 180,
  },
];
export const stickyNoteUserInfoColumns = [
  {
    field: "phonenumber",
    headerName: "numb",
    width: 130,
  },
  {
    field: "country",
    headerName: "Country",
    width: 90,
  },
  {
    field: "clientIP",
    headerName: "Client IP",
    width: 120,
  },
  {
    field: "operator",
    headerName: "Operator",
    width: 90,
  },
  {
    field: "billed",
    headerName: "Billed",
    width: 90,
  },
  {
    field: "cn",
    headerName: "NetworkName",
    width: 90,
  },
  {
    field: "clickID",
    headerName: "Clicked Id",
    width: 120,
  },
  {
    field: "date",
    headerName: "Date",
    width: 180,
  },
];

export const postedDailyColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 160,
  },
  {
    field: "networkname",
    headerName: "Network Name",
    width: 160,
  },
];

export const operatorResponseColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 65,
  },
  {
    field: "Operator",
    headerName: "Operator",
    width: 180,
  },
  {
    field: "response",
    headerName: "Response",
    width: 300,
  },
];
export const infoCPIColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 150,
  },
  {
    field: "ip",
    headerName: "IP Address",
    width: 220,
  },
];

export const subAndRenew = [
  {
    field: "numb",
    headerName: "Phone Number",
    width: 160,
  },
  {
    field: "type",
    headerName: "Type",
    width: 80,
  },
  {
    field: "serverdate",
    headerName: "Date",
    width: 200,
    renderCell: (params) => {
      return <p>{params.row.serverdate + " - " + params.row.servertime}</p>;
    },
  },
];

export const apiSubColumns = [
  {
    field: "msisdn",
    headerName: "MSISDN",
    width: 120,
  },
  {
    field: "action",
    headerName: "Type",
    width: 90,
  },
  {
    field: "networkname",
    headerName: "Affiliate",
    width: 110,
  },
  {
    field: "response",
    headerName: "Response",
    width: 350,
  },
  {
    field: "clickid",
    headerName: "Click/PIN",
    width: 120,
  },

  {
    field: "createdAt",
    headerName: "createdAt",
    width: 220,
  },
];

export const weeklyVisitorsColumns = [
  {
    field: "id",
    headerName: "Visitors",
    width: 140,
  },
  {
    field: "service",
    headerName: "Service",
    width: 230,
  },
];

export const subHistoryColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 160,
  },
  {
    field: "message",
    headerName: "Message",
    width: 160,
  },
];
export const subHistoryAllColumns = [
  {
    field: "numb",
    headerName: "Phone Number",
    width: 140,
  },
  {
    field: "type",
    headerName: "Message Type",
    width: 120,
  },
  {
    field: "serverdate",
    headerName: "Date",
    width: 100,
    // renderCell: (params) => {
    //   return <p>{params.row.serverdate + " - " + params.row.servertime}</p>;
    // },
  },
  {
    field: "servertime",
    headerName: "Time",
    width: 100,
  },
];

export const allSubColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 65,
  },
  {
    field: "operator",
    headerName: "Operator",
    width: 180,
  },
  {
    field: "networkname",
    headerName: "Networkname",
    width: 300,
  },
];

export const servicesColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 65,
  },
  {
    field: "service",
    headerName: "Service",
    width: 120,
  },
  {
    field: "country",
    headerName: "Country",
    width: 100,
  },
  {
    field: "url",
    headerName: "URL",
    width: 350,
  },
  {
    field: "notification_url",
    headerName: "Notification Link",
    width: 350,
  },
  {
    field: "network",
    headerName: "Network Link",
    width: 350,
  },
];

export const servicesBillablePostedColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 165,
  },
  {
    field: "servicename",
    headerName: "Service Name",
    width: 220,
  },
  {
    field: "postednumber",
    headerName: "Max Posted Number",
    width: 220,
  },
  {
    field: "postednumber",
    headerName: "Max Posted Number",
    width: 200,
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Max Posted Number Daily">
          <div>{params.title} Max Posted Number</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
  },
  {
    field: "percentage",
    headerName: "Hourly Percentage %",
    width: 200,
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Hourly Percentage %">
          <div>{params.title} Hourly Percentage %</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
    renderCell: (params) => {
      return <p>{ params.row.percentage + " %" }</p>;
    },
  },
  {
    field: "hour",
    headerName: "Posted Hourly",
    width: 200,
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Count Subscribed Hourly to send the exact Posted percentage">
          <div>{params.title} Posted Hourly</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
    renderCell: (params) => {
      return <p>{ params.row.hour >1 ?  params.row.hour + " hours" : params.row.hour + " hour" }</p>;
    },
  },
];
export const servicesNonBillablePostedColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 165,
  },
  {
    field: "servicename",
    headerName: "Service Name",
    width: 220,
  },
  {
    field: "postednumber",
    headerName: "Max Posted Number",
    width: 200,
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Max Posted Number Daily">
          <div>{params.title} Max Posted Number</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
  },
  {
    field: "randomNumber",
    headerName: "Posted Hourly By Network",
    width: 220,
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Posted Hourly By Network">
          <div>{params.title} Posted Hourly By Network</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
  },
];
export const servicesAdversingNetworkColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
  },
  {
    field: "name",
    headerName: "Network Name",
    width: 140,
  },
  {
    field: "shortname",
    headerName: "Short Name",
    width: 140,
  },
  {
    field: "postbackurl",
    headerName: "PostbackURL",
    width: 560,
  },
  {
    field: "date",
    headerName: "Date",
    width: 100,
  },
  {
    field: "time",
    headerName: "Time",
    width: 110,
  },
];

export const pageViewsColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 165,
  },

  {
    field: "networkname",
    headerName: "NetworkName",
    width: 200,
  },
  {
    field: "country",
    headerName: "Country",
    width: 200,
  },

  {
    field: "page",
    headerName: "Page",
    width: 200,

    renderCell: (params) => {
      return (
        <p>
          {params.row.page === "index" || params.row.page === "indexdragon"
            ? "Click"
            : params.row.page === "numberCheck"
            ? "MSISDN"
            : params.row.page === "numbercheckconfirm"
            ? "Success"
            : "Failed"}
        </p>
      );
    },
  },
];

export const advertisingBillablePostedColumns = [
  {
    field: "count",
    headerName: "Count",
    width: 160,
  },
  {
    field: "networkname",
    headerName: "Network Name",
    width: 240,
  },
  {
    field: "service",
    headerName: "Service",
    width: 240,
  },
  {
    field: "payout",
    headerName: "Payout",
    width: 240,
    renderCell: (params) => {
      return <p>{"$" + numeral(params.row.payout).format("0,0.00")}</p>;
    },
  },
];
export const allServicesLogsAdvPostedColumns = [
  {
    field: "servicename",
    headerName: "Service",
    width: 120,
  },
  {
    field: "url",
    headerName: "Landing Page",
    width: 320,
  },
  {
    field: "networkname",
    headerName: "Network Name",
    width: 130,
  },
  {
    field: "country",
    headerName: "Country",
    width: 110,
  },

  {
    field: "type",
    headerName: "Type",
    width: 110,
  },
  {
    field: "count",
    headerName: "Count",
    width: 110,
  },
  {
    field: "percentage",
    headerName: "percentage",
    width: 110,
    renderCell: (params) => {
      return <p>{numeral(params.row.percentage).format("0,0.00") + " %"}</p>;
    },

    // {
    //   field: "percentage",
    //   headerName: "percentage",
    //   width: 110,
    //   renderCell: (params) => {
    //     const percentage = params.row.percentage;
    //     const hue = (percentage / 100) * 120; // Map percentage to hue (0-120 degrees)
    //     const color = `hsl(${hue}, 100%, 50%)`; // Create HSL color string
    //     return (
    //       <p style={{ color, fontWeight: "bold" }}>
    //         {numeral(percentage).format("0,0.00") + " %"}
    //       </p>
    //     );
    //   },
  },
];
export const allServicesPostedColumns = [
  {
    field: "servicename",
    headerName: "Service",
    width: 120,
  },
  {
    field: "networkname",
    headerName: "Network Name",
    width: 130,
  },
  {
    field: "country",
    headerName: "Country",
    width: 110,
  },

  {
    field: "type",
    headerName: "Type",
    width: 110,
  },
  {
    field: "count",
    headerName: "Count",
    width: 110,
  },
  {
    field: "pixels",
    headerName: "Pixels",
    width: 110,
  },

  {
    field: "sales_views_percentage",
    headerName: "CR S%",
    width: 110,
    renderCell: (params) => {
      return (
        <p>
          {numeral(params.row.sales_views_percentage).format("0,0.00") + " %"}
        </p>
      );
    },
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Sales  / VIEW  * 100">
          <div>{params.title} CR S%</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
  },

  {
    field: "sales_pixels_percentage",
    headerName: "CR P%",
    width: 110,
    renderCell: (params) => {
      return (
        <p>
          {numeral(params.row.sales_pixels_percentage).format("0,0.00") + " %"}
        </p>
      );
    },
    renderHeader: (params) => (
      <GridToolbarContainer>
        <Tooltip title="Sales  / Pixels  * 100">
          <div>{params.title} CR P%</div>
        </Tooltip>
      </GridToolbarContainer>
    ),
  },
];

export const allMtSubHistColumns = [
  {
    field: "servicename",
    headerName: "Service",
    width: 120,
  },
  {
    field: "networkname",
    headerName: "Network Name",
    width: 130,
  },
  {
    field: "country",
    headerName: "Country",
    width: 110,
  },
];
