import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const ChartCircle = ({
  chartLabel,
  nameOfChart,
  NumberOfTotal,
  colorOfTotal,
  borderColor,
}) => {
  return (
    <div>
      <Doughnut
        data={{
          labels: chartLabel,
          datasets: [
            {
              label: nameOfChart,
              data: NumberOfTotal,
              backgroundColor: colorOfTotal,
              borderColor: borderColor,
              // borderColor: [
              //   'gray'
              // ],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        // width={500}
        // height={200}
      />
    </div>
  );
};

export default ChartCircle;
