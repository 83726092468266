import { DataGrid } from "@mui/x-data-grid";
import { activeSubColumns } from "../../datatablesource";
import { useEffect, useState } from "react";

import { fetchUrl } from "../../../../functions/fetchUrl";

const ActiveSubTimWe = ({ table }) => {
  useState("Loading...");

  console.log("tablename endpoint: " + table)
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    const fetchData = async () => {
      const response = await fetchUrl({ apiName: table, method: "get" });
      if (response.success) {
        setData(response.result);
      }
    };
    fetchData();
  }, [table]);

  return (

    <>
      <DataGrid
        className="ml-1"
        rows={data}
        columns={activeSubColumns}
        pageSize={15}
        rowsPerPageOptions={[5]}
        style={{ height: "60vh", marginLeft: "50px" }}
        getRowId={(data) => data.id}
      />
    </>

  );
};

export default ActiveSubTimWe;
