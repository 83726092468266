import "./subwidget.css";
import PostedBillable from "./PostedBillable";
import PostedNonBillable from "./PostedNonBillable";
import SubAndRenew from "./SubAndRenew";
import UserInfo from "./UserInfo";

const StickyNoteWidget = ({ table, role }) => {
  const colors = ["red", "green", "blue", "brown"];
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomIndex];

  // useEffect(() => {
  //   window.location.reload();
  // }, []);

  return (
    <div
      className="col-10 "
      style={{
        position: "relative",
        left: "250px",
        top: "100px",
      }}
    >
      <div>

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Posted Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedBillable table={table + "/billableposted"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Posted Non-Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedNonBillable table={table + "/nonbillableposted"} />
              </div>
            </div>
          </div>
        )}

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                All Notifications{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <SubAndRenew table={table + "/getallnotifications"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                User Info{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <UserInfo table={table + "/getuserinfo"} />
              </div>
            </div>
          </div>
        )}

        {/* End */}
      </div>
    </div>
  );
};

export default StickyNoteWidget;
