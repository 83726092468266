import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditAdmin from "./EditAdmin";
import DeleteBox from "../../../delete-box/DeleteBox";
import WithShowAddEditDeleteOfAllPages from "../../../HOC/WithShowAddEditDeleteOfAllPages";
import { fetchUrl } from "../../../../functions/fetchUrl";

const Admin = ({
  // showAddBox,
  // showdeleteBox,
  // showEditBox,
  // showAddBoxFun,
  // showDeleteBoxFun,
  // showEditBoxFun,
  // dataOfTable,
  re_render,
}) => {
  const [dataSendingToDeleteBox, setDataSendingToDeleteBox] = useState({});
  const [showdeleteBox, setShowDeleteBox] = useState(false);
  // console.log("re_render",re_render)
  const [search, setSearch] = useState();
  const [resultSearch, setResultSearch] = useState([]);
  const [textOfTableWhenSearching, setTextOfTableWhenSearching] =
    useState("Loading...");

  const [dataOfTable, setDataOfTable] = useState([]);
  useEffect(async () => {
    const data = await fetchUrl({ apiName: "admin", method: "get" });
    if (data.success) {
      setDataOfTable(data.result);
      // console.log(data)
    } else {
      console.log(data);
    }
  }, [showdeleteBox, re_render]);

  useEffect(() => {
    if (search) {
      const stringConvertedTOUpperCase = search.toUpperCase();
      var matches = dataOfTable.filter((s) => {
        let fieldConvertedTOUpperCase = s.username?.toUpperCase();

        return fieldConvertedTOUpperCase?.startsWith(
          stringConvertedTOUpperCase
        );
      });

      setResultSearch(matches);
      if (matches.length !== 0) {
        setTextOfTableWhenSearching("Loading ...");
      } else {
        setTextOfTableWhenSearching("No data");
      }
    } else {
      setResultSearch([]);
    }
  }, [search]);
  const showDeleteBoxFun = () => {
    setShowDeleteBox(!showdeleteBox);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true },
    {
      field: "username",
      headerName: "Username",
      width: 190,
      sortable: false,
    },
    {
      field: "email",
      headerName: "email",
      width: 250,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 190,
      sortable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "This column has a button edit and delete user.",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            {/* <button
                            className='btn btn-info'
                            onClick={() => {
                                set"Data",DataSendingToEditBox(params.row)
                                showEditBoxFun()
                            }}
                        >
                            Edit
                        </button> */}
            <FontAwesomeIcon
              className="text-danger icon-delete ml-3"
              icon={faTrash}
              onClick={() => {
                setDataSendingToDeleteBox(params.row);
                showDeleteBoxFun();
              }}
            />
          </div>
        );
      },
    },
  ];
  // const rows = [
  //     { id: 1, username: 'yousef ...', mobileNumber: 14568795 },
  //     { id: 2, username: 'mahmoud ...', mobileNumber: 45856214 },
  //     { id: 3, username: 'khaled ...', mobileNumber: 25879461 },
  //     { id: 4, username: 'ali ...', mobileNumber: 12345678 },
  //     { id: 5, username: 'mhd ...', mobileNumber: 14568795 },
  //     { id: 6, username: 'ayman ...', mobileNumber: 14568795 },
  //     { id: 7, username: 'something ...', mobileNumber: 14568795 },
  //     { id: 8, username: 'something ...', mobileNumber: 14568795 },
  //     { id: 9, username: 'something ...', mobileNumber: 14568795 },
  //     { id: 10, username: 'something ...', mobileNumber: 14568795 },

  // ];
  const rows = search ? resultSearch : dataOfTable;
  return (
    <div className="col-12 mt-5 ">
      {showdeleteBox ? (
        <DeleteBox
          closeBox={showDeleteBoxFun}
          data={dataSendingToDeleteBox.username}
          name={dataSendingToDeleteBox.role}
          APIName={"admin"}
          id={dataSendingToDeleteBox.id}
        />
      ) : (
        //  showEditBox ? <EditAdmin
        //     data={dataSendingToEditBox}
        //     showEditingAdminBox={showEditBoxFun}
        // /> :
        <div>
          <div className="d-flex justify-content-between align-items-center pt-4 ">
            <h2 className="text-secondary font-weight-bold">
              Administrators accounts:
            </h2>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search ..."
              aria-label="Search"
              onChange={(e) => setSearch(e.target.value)}
            />

            {/* <Link to={'/superadmin/create-account'}>
                                    <button
                                        className='btn btn-primary'
                                    >
                                        Add
                                    </button>
                                </Link> */}
          </div>
          <div className="m-5 h-75">
            <DataGrid
              className="ml-5"
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableColumnMenu
              style={{ height: "60vh", marginLeft: "50px" }}
              components={{
                NoRowsOverlay: () => (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <h3>{textOfTableWhenSearching}</h3>
                  </div>
                ),
              }}
              disableSelectionOnClick
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
