import { useRef, useState } from "react"
import fetchURLForm from "../../../functions/fetchURLForm"

const EditWidget = ({
    data,
    showEditBoxFun

}) => {
    const [ID, setID] = useState(data.id)
    const [imageWidget, setImageWidget] = useState(null)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const name = useRef('')
    // const description = useRef('')

    // to set imageWidget state as an image 
    const fileSelectedHandler = event => {
        setImageWidget(event.target.files[0])
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        const body = new FormData()
        body.append("files", imageWidget);
        // body.append("description", description.current.value);
        body.append("name", name.current.value);
        const response = await fetchURLForm(/*url = */`category/${ID}`,/*method = */'PUT',/*data = */ body)
       console.log("responsr", response)
        if(response?.data?.success){
            setSuccess(true)
        }else{
            setError(true)
            console.log(response)
        }
        
        // setTimeout(() => {
        //     showEditBoxFun()
        // }, 1000);
        
    }
    return (
        <div id="addNoteModal" className="modal" style={{
            zIndex:'99999'

          }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Edit Category <span className="text-warning">{data.name}</span></h2>
                </div>
                <div>
                    {
                        success && <div className="alert alert-success" role="alert">
                            Updated Successfully...
                            
                        </div>
                    }
                    {
                        error && <div className="alert alert-danger" role="alert">
                           Updated Failed Try Again...
                           
                        </div>
                    }
                </div>
                <form className='w-100 p-5' encType="multipart/form-data" onSubmit={handleUpdate}>
                    <div className='mt-2'>
                        <label htmlFor="nameWidget">Category Name</label>
                        <input
                            type='text'
                            id="nameWidget"
                            placeholder='Name Of Widget'
                            className='form-control'
                            required
                            defaultValue={data.name}
                            ref={name}
                        />
                    </div>
                    {/* <div className='mt-2'>
                        <label htmlFor="description">Description</label>
                        <input
                            type='text'
                            id="description"
                            placeholder='Description'
                            className='form-control'
                            required
                            defaultValue={data.description}
                            ref={description}
                        />
                    </div> */}
                    <div className='mt-2'>
                        <label htmlFor="image">Image</label>
                        <input
                            type='file'
                            id="image"
                            className='form-control'
                            // required
                            onChange={fileSelectedHandler}
                        />
                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"

                        >
                            Update
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={showEditBoxFun}
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default EditWidget