import { useEffect, useState } from "react";
import fetchUrlForm from "../../../../functions/fetchURLForm";
import ChartCircle from "./charts/ChartCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import spinnerGif from "../../../../images/spinner.gif";

const CountSubUnSubDailyTimWe = ({ table }) => {
  const [orderData, setOrderData] = useState({
    success: true,
    result: {
      active: 0,
      unsub: 0,
      // posted: 0,
      // sub: 0,
    },
  });
  const [fetching, setfetching] = useState("fetching");
  useEffect(() => {
    setfetching("fetching")

    setOrderData({
      success: true,
      result: {
        active: 0,
        unsub: 0,
        // posted: 0,
        // sub: 0,
      },
    });
    const fetchData = async () => {
      const response = await fetchUrlForm(table, "GET");

      if (response.data.success) {
        setOrderData(response.data);
        setfetching("complete")
      }
    };
    fetchData();
  }, [table]);
  console.log(fetching);

  return (
    <div>
      {/* Orders card  */}
      <div className="col-5">
        <div
          className="shadow  p-2"
          style={{ width: "400px", height: "450px" }}
        // style={{ width: "500px", height: "550px" }}
        >
          <div>
            {/* <span className='font-weight-bold'><u>Orders:</u></span> */}
            <div className="d-flex justify-content-betweenalign-items-center">
              <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "rgb(54, 162, 235)" }}
                />
                <small>Active</small>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "#0f0" }}
                />
                <small>Unsub</small>
              </div>
              {/* <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "#f00" }}
                />
                <small>Posted</small>
              </div> */}
              {/* <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "#0fff" }}
                />
                <small>Sub</small>
              </div> */}
            </div>
          </div>
          <div className="p-3 mt-4">
            {fetching === 'fetching' ? (
              <div>
                <img
                  src={spinnerGif}
                  alt="Loading..."
                  style={{
                    width: "100px",
                    height: "100px",
                    marginLeft: "130px",
                    marginTop: "90px",
                  }}
                />
              </div>
            ) : (
              <ChartCircle
                chartLabel={[
                  "Total Active",
                  "Total Unsub",
                  // "Total Posted",
                  // "Total Sub",
                ]}
                nameOfChart={"Users"}
                NumberOfTotal={[
                  orderData.result.active > 0 ? orderData.result.active : 1,
                  orderData.result.unsub > 0 ? orderData.result.unsub : 1,
                  // orderData.result.posted > 0 ? orderData.result.posted : 1,
                  // orderData.result.sub > 0 ? orderData.result.sub : 1,
                ]}
                colorOfTotal={["rgb(54, 162, 235)", "#0f0"]}
                borderColor={["rgb(54, 162, 235)", "#0f0"]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountSubUnSubDailyTimWe;
