import { DataGrid } from "@mui/x-data-grid";

const RecommendationRestaurants = ({ data }) => {
  const URLImage = "https://dashboard.mobimartech.com:3456/images/";

  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },
    {
      field: "logo",
      headerName: "Logo",
      width: 110,
      sortable: false,
      renderCell: (params) => {
        return (
          <img
            key={params.row.logo}
            className="logo-restaurant"
            src={`${URLImage}/${params.row.image}`}
            alt="icon"
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 135,
      sortable: false,
    },
  ];
  const rows = data ? data : [];

  return (
    <div className="mt-3 data-grid">
      <span className="font-weight-bold">
        <u>Worst Restaurants:</u>
      </span>
      <DataGrid
        className=""
        rows={rows}
        columns={columns}
        pageSize={3}
        rowsPerPageOptions={[3]}
        rowHeight={49}
        pagination={true}
        style={{ height: "42vh" }}
      />
    </div>
  );
};

export default RecommendationRestaurants;
