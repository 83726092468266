import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import SessionContext from "../session/SessionContext";
import {
  faFutbol,
  faShield,
  faMosque,
  faGift,
  faNetworkWired,
  faMoneyCheckDollar,
  faGlobe,
  faBus,
  faScissors,
  faFileInvoiceDollar,
  faFileInvoice,
  faPaw,
  faBook,
  faHatCowboy,
  faUserSecret,
  faFileShield,
} from "@fortawesome/free-solid-svg-icons";
const AccountantSidebar = () => {
  const {
    actions: { signout },
  } = useContext(SessionContext);
  return (
    <div
      className="border-right h-100 "
      id="sidebar"
      style={{
        width: "250px",
        padding: "0 10px",
        position: "fixed",
        top: "81px",
        left: "0",
        zIndex: "9999",
        backgroundColor: "#fff",
        overflow: "auto",
        paddingBottom: "50px",
      }}
    >
      <ul className="list-unstyled ">
        <NavLink to={"islamnquran"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileShield}
                className="text-primary pr-3"
              />
              IslamNQuran
            </p>
          </li>
        </NavLink>
        {/* <NavLink to={'accountant-home'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faChartLine}
                                className='text-primary pr-3' />
                            Home
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'accountant-stores'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faArchway}
                                className='text-primary pr-3' />
                            Stores
                        </p>
                    </li>
                </NavLink>
                
                <NavLink to={'accountant-drivers'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faCar}
                                className='text-primary pr-3' />
                            Drivers
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'accountant-wallet'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faList}
                                className='text-primary pr-3' />
                            Wallet
                        </p>
                    </li>
                </NavLink> */}
      </ul>
      <button className="btn btn-dark mb-4 ml-4" onClick={signout}>
        LOGOUT
      </button>
    </div>
  );
};

export default AccountantSidebar;
