import "./users.css";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import DeleteBox from "../../delete-box/DeleteBox";
import AddStoreUser from "./AddStoreUser";
import WithShowAddEditDeleteOfAllPages from "../../HOC/WithShowAddEditDeleteOfAllPages";
import fetchURLForm from "../../../functions/fetchURLForm";

const Users = ({
  showAddBox,
  showdeleteBox,
  showEditBox,
  showAddBoxFun,
  showDeleteBoxFun,
  showEditBoxFun,
  dataOfTable,
  textOfTableWhenSearching,
  resultSearch,
  setSearchFun,
  search,
}) => {
  const [dataSendingToDeleteBox, setDataSendingToDeleteBox] = useState({});

  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true },
    {
      field: "name",
      headerName: "Name",
      width: 130,
      sortable: false,
    },
    {
      field: "username",
      headerName: "Username",
      width: 130,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Address",
      width: 130,
      sortable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 140,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 140,
      sortable: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 140,
      sortable: false,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      width: 160,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <p>{params.row.created_at.replace(".000Z", " ").replace("T", " ")}</p>
        );
      },
    },
    {
      field: "ordersTaken",
      headerName: "Orders Taken",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Link to={params.row.id.toString()}>
              <button className="btn btn-info">View</button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a button edit and delete user.",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              className="text-danger icon-delete ml-3"
              icon={faTrash}
              onClick={() => {
                setDataSendingToDeleteBox(params.row);
                showDeleteBoxFun();
              }}
            />
          </div>
        );
      },
    },
  ];

  const rows = search ? resultSearch : dataOfTable;
  return (
    <div
      className="col-10"
      style={{
        position: "relative",
        left: "220px",
        top: "100px",
      }}
    >
      {showAddBox ? (
        <AddStoreUser showAddStoreBox={showAddBoxFun} />
      ) : showdeleteBox ? (
        <DeleteBox
          data={dataSendingToDeleteBox.username}
          APIName="user"
          id={dataSendingToDeleteBox.id}
          closeBox={showDeleteBoxFun}
          name={"User"}
        />
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-4 ">
            <h2 className="text-secondary font-weight-bold">Users:</h2>
            <input
              className="form-control w-25 mr-5"
              type="search"
              placeholder="Search ..."
              aria-label="Search"
              onChange={(e) => setSearchFun(e.target.value)}
            />
            <button className="btn btn-primary" onClick={showAddBoxFun}>
              Add
            </button>
          </div>
          <div className="m-5 h-75">
            <DataGrid
              className="ml-5"
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableColumnMenu
              style={{ height: "63vh", marginLeft: "50px" }}
              components={{
                NoRowsOverlay: () => (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <h3>{textOfTableWhenSearching}</h3>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WithShowAddEditDeleteOfAllPages(Users, "storeUsers");
