import { useRef, useState } from "react";
import fetchURLForm from "../../../functions/fetchURLForm";

const AddService = ({ showAddStoreBox }) => {
  const service = useRef("");
  const country = useRef("");

  const url = useRef("");
  const notification_url = useRef("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSave = async (event) => {
    event.preventDefault();
    const body = new FormData();

    body.append("service", service.current.value);
    body.append("country", country.current.value);
    body.append("url", url.current.value);
    body.append("notification_url", notification_url.current.value);

    const response = await fetchURLForm(
      /*url = */ "services",
      /*method = */ "POST",
      /*data = */ body
    );
    if (response.data.success) {
      setSuccess(true);
    } else {
      setError(true);
      console.log(response);
    }
  };
  const hideAlert = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <div
      id="addNoteModal"
      className="modal"
      style={{
        zIndex: "999",
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>Add New Service</h2>
        </div>
        <div>
          {success && (
            <div className="alert alert-success" role="alert">
              Created Successfully...
            </div>
          )}
          {error && (
            <div className="alert alert-anger" role="alert">
              Created Failed Try Again...
            </div>
          )}
        </div>
        <form
          className="w-100 p-5"
          encType="multipart/form-data"
          onSubmit={handleSave}
        >
          <div className="mt-2">
            <label htmlFor="serviceWidget">Service Name</label>
            <input
              ref={service}
              type="text"
              id="serviceWidget"
              placeholder="Name Of Service"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="country">Country</label>
            <input
              ref={country}
              type="text"
              id="country"
              placeholder="KSA, KWT"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="url">Landing URL</label>
            <input
              ref={url}
              type="text"
              id="url"
              placeholder="Landing Page for this service"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="notification_url">Notification Link</label>
            <input
              ref={notification_url}
              type="text"
              id="notification_url"
              placeholder="Notification URL"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={showAddStoreBox}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddService;
