import { useRef, useState } from 'react'
import fetchURLForm from '../../functions/fetchURLForm'
import logo from './../../images/mmt.png'
import VerfiyCode from './VerfiyCode'

const ForgetPassword = () => {
    const [alert, setAlert] = useState(false)
    const [data, setData] = useState({})
    const [code, setCode] = useState('')
    const [showVerifyCodeBox, setShowVerifyCodeBox] = useState(false)
    const email = useRef()
    const handleClickBtn = async (e) => {
        e.preventDefault()
        const body = {
            email: email.current.value
        }
        // window.location= 'http://localhost:3000/verify-code'
        if (email.current.value) {
            const response = await fetchURLForm(
                /*url = */`forgetpassword/emailVerification`,
                /*method = */`POST`,
                /*data = */ body
            )
            if (response.data.result.length === 0) {
                setAlert(true)
                setShowVerifyCodeBox(false)
            } else {
                const emailsend ={
                    email: email.current.value
                }
                // console.log("emailsend",emailsend)

                const response1 = await fetchURLForm(
                    /*url = */`forgetpassword/sendEmail`,
                    /*method = */`POST`,
                    /*data = */ emailsend
                )
                if(response1.data.success){
                    setCode(response1.data.code)
                }
                // console.log("response1",response1)
                setData(response.data)
                setShowVerifyCodeBox(true)
                
               
            }

        }

    }
    const clearAlert = () => {
        setAlert(false)
        // setShowVerifyCodeBox(false)
    }
    return (
        <>
            {
                showVerifyCodeBox ? <VerfiyCode
                 data={data} 
                 code={code}
                 /> : (
                    <div id="login-form-wrap">
                        <div className='d-flex justify-content-center p-3'>
                            <img className='logo' src={logo} />
                        </div>
                        {
                            alert && <div className="alert alert-danger" role="alert">
                                This Email Does't Exist, try again
                            </div>
                        }
                        <div>
                            <p>Please Enter Your Email To Send A Code to your Email</p>
                        </div>

                        <form id="login-form" onSubmit={handleClickBtn}>
                            <div>
                                <input
                                    ref={email}
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Example@email.com"
                                    required
                                    onChange={clearAlert}
                                />
                            </div>
                            <div>
                                <button
                                    className='mb-5 login-button'
                                    type="submit"
                                    id="login"

                                > Send A Code
                                </button>
                            </div>
                        </form>
                    </div>
                )
            }

        </>
    )
}

export default ForgetPassword