import "./subwidget.css";

import ActiveSubTimWe from "./ActiveSubTimWE";
import CountSubUnSubDailyTimWe from "./CountSubUnSubDailyTimeWe";
import SubHistoryTimWeBH from "./SubHistoryTimWeBH";
import SubPostedTimWe from "./SubPostedTimWe";

const SubWidgetTimWe = ({ table, role }) => {
    const colors = ["red", "green", "blue", "brown"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    const randomColor = colors[randomIndex];


    return (
        <div
            className="col-10 "
            style={{
                position: "relative",
                left: "250px",
                top: "100px",
            }}
        >
            <div>
                <div className="row  p-3"></div>

                {role !== "accountant" && (
                    <div className="row justify-content-center">

                        <div className="col-5 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                Daily Report BH{" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <CountSubUnSubDailyTimWe table={table + "/countSubUnSubBH"} />
                            </div>
                        </div>

                        <div className="col-5 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                Daily Report OM{" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <CountSubUnSubDailyTimWe table={table + "/countSubUnSubOM"} />
                            </div>
                        </div>
                    </div>
                )}

                {role !== "accountant" && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-10 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                Sub History BH {" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <SubHistoryTimWeBH table={table + "/subHistoryBH"} />
                            </div>
                        </div>
                    </div>
                )}

                {role !== "accountant" && (
                    <div className="row justify-content-center">
                        <div className="col-10 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                Sub History OM {" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <SubHistoryTimWeBH table={table + "/subHistoryOM"} />
                            </div>
                        </div>
                    </div>
                )}
                {/* getPostedBH
                getPostedOM */}

                {role !== "accountant" && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-5 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                Posted BH {" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <SubPostedTimWe table={table + "/getPostedBH"} />
                            </div>
                        </div>

                        <div className="col-5 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                Posted OM {" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <SubPostedTimWe table={table + "/getPostedOM"} />
                            </div>
                        </div>
                    </div>
                )}


                {role !== "accountant" && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-5 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                ActiveSub BH {" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <ActiveSubTimWe table={table + "/BH"} />
                            </div>
                        </div>

                        <div className="col-5 shadow ml-5">
                            <h2 className="text-secondary font-weight-bold">
                                ActiveSub OM {" "}
                                <span style={{ color: randomColor }}>
                                    {table === "converseaitimwe" ? <>ConverseAI</> : <>KoraClub</>}
                                </span>
                            </h2>
                            <div>
                                <ActiveSubTimWe table={table + "/OM"} />
                            </div>
                        </div>
                    </div>
                )}




                {/* End */}
            </div>
        </div>
    );
};

export default SubWidgetTimWe;
