import React, { useEffect, useState } from 'react'
import ChartLine from './chart_line/ChartLine'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import './customer-services.css'
import fetchURLForm from '../../../../functions/fetchURLForm'
const CustomerServices = () => {
  const [total, setTotal] = useState({})
  const [pending, setPending] = useState({})
  const [solved, setSolved] = useState({})
  useEffect(async () => {
    const response = await fetchURLForm(
      /*url = */`complaints/getReport/total`,
      /*method = */'GET',
    )
    if (response.data.success) {
      setTotal(response.data.data.totalComplaints)
      // console.log('total',response.data.data.totalComplaints)
    } else {
      // setError(true)
      console.log(response)
    }
  }, [])
  useEffect(async() => {
    const response = await fetchURLForm(
      /*url = */`complaints/getReport/solved`,
      /*method = */'GET',
    )
    if (response.data.success) {
      setSolved(response.data.data.solvedComplaints)
      // console.log('solved',response.data.data.solvedComplaints)
    } else {
      // setError(true)
      console.log(response)
    }
  }, [])
  useEffect(async() => {
    const response = await fetchURLForm(
      /*url = */`complaints/getReport/pending`,
      /*method = */'GET',
    )
    if (response.data.success) {
      setPending(response.data.data.pendingComplaints)
      // console.log('pending',response.data.data.pendingComplaints)
    } else {
      // setError(true)
      console.log(response)
    }
  }, [])

  return (
    <>
      <div className='p-3 font-weight-bold'>
        <u>
          Customer Services:
        </u>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <span>
          <FontAwesomeIcon
            icon={faSquare}
            className='total-number-compalints'
          />
          <small className='pl-3'>Total Complaints</small>
        </span>
        <span>
          <FontAwesomeIcon
            icon={faSquare}
            className='pending-reqests pl-1'
          />
          <small className='pl-3'>Pending Requests</small>
        </span>
        <span>
          <FontAwesomeIcon
            icon={faSquare}
            className='solved-requests pl-1'
          />
          <small className='pl-3'>Solved Requests</small>
        </span>
      </div>
      <div className='mt-5'>
        <ChartLine

          // first array: total number of Complaints
          // second array: pending Requests
          // third array: solved requests
          data={
            [
              total,
              pending,
              solved
            ]
          }
        />
      </div>
    </>
  )
}

export default CustomerServices