import { useState } from "react"
import { fetchUrl } from "../../functions/fetchUrl"

const DeleteBox = ({
    closeBox,
    data,
    name,
    APIName,
    id
}) => {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const HandleDelete = async () => {
        
        const data = await fetchUrl({ apiName: `${APIName}/${id}`, method: 'delete' })
        if (data.success) {
            setSuccess(true)
        } else {
            setError(true)
        }
        setTimeout(() => {
            closeBox()
        }, 1000);
    }
    return (
        <div id="addNoteModal" className="modal" style={{
            zIndex: '99999'
        }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Delete {name} <span className='text-warning'>{data}</span></h2>
                </div>
                {
                    success && <div className="alert alert-success" role="alert">
                        Deleted Successfully...
                    </div>
                }
                {
                    error && <div className="alert alert-anger" role="alert">
                        Deleted Failed, Try Again...
                    </div>
                }
                <div>
                </div>
                <h4 className='p-5'>Are You Sure You Want To Delete This {name}?</h4>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={HandleDelete}
                    >
                        Delete
                    </button>
                    <button
                        type="button"
                        className=" btn btn-secondary"
                        onClick={closeBox}
                    >
                        Cancel
                    </button>
                </div>


            </div>
        </div>)
}

export default DeleteBox