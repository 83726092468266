import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import AddNonBillablePosted from "./AddNonBillablePosted";
import EditNonBillablePosted from "./EditNonBillablePosted";
import DeleteBox from "../../delete-box/DeleteBox";
import WithShowAddEditDeleteOfAllPages from "../../HOC/WithShowAddEditDeleteOfAllPages";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { servicesNonBillablePostedColumns } from "../datatablesource";

const NonBillablePosted = ({
  showAddBox,
  showdeleteBox,
  showEditBox,
  showAddBoxFun,
  showDeleteBoxFun,
  showEditBoxFun,
  dataOfTable,
  textOfTableWhenSearching,
  resultSearch,
  setSearchFun,
  search,
}) => {
  const useStyles = makeStyles({
    dataGrid: {
      width: "1100px",
    },
  });
  const classes = useStyles();

  const [dataSendingToDeleteBox, setDataSendingToDeleteBox] = useState({});
  const [dataSendingToEditBox, setDataSendingToEditBox] = useState({});

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      description: "This column has a button edit and delete user.",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-info"
              onClick={() => {
                setDataSendingToEditBox(params.row);
                showEditBoxFun();
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
  ];

  const rows = search ? resultSearch : dataOfTable;
  return (
    <div
      className="col-10 "
      style={{
        position: "relative",
        left: "220px",
        top: "100px",
      }}
    >
      {showAddBox ? (
        <AddNonBillablePosted showAddStoreBox={showAddBoxFun} />
      ) : showdeleteBox ? (
        <DeleteBox
          APIName={"services/billableposted"}
          name={"Services"}
          id={dataSendingToDeleteBox.id}
          closeBox={showDeleteBoxFun}
          data={dataSendingToDeleteBox.name}
        />
      ) : showEditBox ? (
        <EditNonBillablePosted
          showEditingStoreBox={showEditBoxFun}
          data={dataSendingToEditBox}
        />
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-4 ">
            <h2 className="text-secondary font-weight-bold">
              Non-Billable Posted:
            </h2>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search ..."
              aria-label="Search"
              onChange={(e) => setSearchFun(e.target.value)}
            />
            <button className="btn btn-primary" onClick={showAddBoxFun}>
              Add
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center ">
            <div className="mt-5 ">
              <DataGrid
                className={classes.dataGrid}
                rows={rows}
                columns={servicesNonBillablePostedColumns.concat(actionColumn)}
                pageSize={12}
                rowsPerPageOptions={[9]}
                style={{ height: "70vh" }}
                components={{
                  NoRowsOverlay: () => (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <h3>{textOfTableWhenSearching}</h3>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithShowAddEditDeleteOfAllPages(
  NonBillablePosted,
  "services/nonbillableposted"
);
