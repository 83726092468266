import { useRef, useState } from 'react'
import fetchURLForm from '../../../functions/fetchURLForm'
import StoreCombobox from './store-combobox/StoreCombobox'
import { fetchUrl } from "../../../functions/fetchUrl";

const AddStore = ({
    showAddStoreBox,
}) => {
    
    const name = useRef('')
    const username = useRef('')
    const password = useRef('')
    const email = useRef('')
    const phoneNumber = useRef('')

    const [image, setImage] = useState(null)
    const [storeId, setstoreId] = useState('')

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    

    // to set imageWidget state as an image 
    const fileSelectedHandler = event => {
        setImage(event.target.files[0])
    }
    const handleSave = async (event) => {
        // console.log ("SelectCat on Handle: " , categoryId);
        event.preventDefault()

        const reqBody = {
            storeId: storeId,
            name: name.current.value,
            username: username.current.value,
            password: password.current.value,
            email: email.current.value,
            phoneNumber: phoneNumber.current.value,
        }

        const response = await fetchUrl({ apiName: 'storeUsers', method: 'post', reqBody })

        console.log("response", response.success)

        if(response.success){
            setSuccess(true)
        }else{
            setError(true)
            console.log(response)
        }
    }
    const hideAlert = () => {
        setSuccess(false)
        setError(false)
    }
    const setstoreIdFun = (id) => {
        setstoreId(id)
        // console.log ("SelectCat onSet: ", id)
    }
    
    return (
        <div id="addNoteModal" className="modal" style={{
            zIndex:'999'

          }}>

            <div className="modal-content">
                <div className="modal-header">
                    <h2>Add New Store User</h2>
                </div>
                <div>
                    {
                        success && <div className="alert alert-success" role="alert">
                            Created Successfully...
                        </div>
                    }
                    {
                        error && <div className="alert alert-anger" role="alert">
                            Created Failed Try Again...
                        </div>
                    }
                </div>
                <form 
                className='w-100 p-5' 
                encType="multipart/form-data"
                onSubmit={handleSave} >
                   <div className='mt-2'>
                        <label htmlFor="name">Name</label>
                        <input
                        ref={name}
                            type='text'
                            id="name"
                            placeholder='name'
                            className='form-control'
                            required
                            onChange={hideAlert}
                        />
                    </div>
                    <div className='mt-2' >
                        <label htmlFor="storeId">StoreName</label>
                        
                        <StoreCombobox
                            setstoreIdFun={setstoreIdFun}
                        />
                    </div>
                    

                    <div className='mt-2'>
                        <label htmlFor="username">username</label>
                        <input
                        ref={username}
                            type='text'
                            id="username"
                            placeholder='username'
                            className='form-control'
                            required
                            onChange={hideAlert}

                        />
                    </div>
                    <div className='mt-2'>
                        <label htmlFor="password">password</label>
                        <input
                        ref={password}
                            type='password'
                            id="password"
                            placeholder='password'
                            className='form-control'
                            required
                            onChange={hideAlert}

                        />
                    </div>
                    <div className='mt-2'>
                        <label htmlFor="email">email</label>
                        <input
                        ref={email}
                            type='text'
                            id="email"
                            placeholder='email'
                            className='form-control'
                            required
                            onChange={hideAlert}

                        />
                    </div>
                    

                    <div className='mt-2'>
                        <label htmlFor="phoneNumber">phoneNumber</label>
                        <input
                        ref={phoneNumber}
                            type='number'
                            id="phoneNumber"
                            placeholder='phoneNumber'
                            className='form-control'
                            required
                            onChange={hideAlert}

                        />
                    </div>
                   

                    {/* <div className='mt-2'>
                        <label htmlFor="image">Image</label>
                        <input
                            type='file'
                            id="image"
                            className='form-control'
                            required
                            onChange={fileSelectedHandler}
                        />
                    </div> */}


                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={showAddStoreBox}
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}
export default AddStore