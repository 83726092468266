import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFutbol,
  faShield,
  faMosque,
  faGift,
  faNetworkWired,
  faMoneyCheckDollar,
  faGlobe,
  faBus,
  faScissors,
  faFileInvoiceDollar,
  faFileInvoice,
  faPaw,
  faBook,
  faHatCowboy,
  faUserSecret,
  faFileShield,
} from "@fortawesome/free-solid-svg-icons";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import SessionContext from "../session/SessionContext";

const AdminSidebar = () => {
  const {
    actions: { signout },
  } = useContext(SessionContext);
  return (
    <div
      className="border-right h-100"
      id="sidebar"
      style={{
        width: "250px",
        padding: "0 10px",
        position: "fixed",
        top: "81px",
        left: "0",
        zIndex: "9999",
        backgroundColor: "#fff",
        overflow: "auto",
        paddingBottom: "50px",
      }}
    >
      <ul className="list-unstyled ">
        <NavLink to={"advertisement"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGlobe} className="text-primary pr-3" />
              Advertising
            </p>
          </li>
        </NavLink>

        <NavLink to={"sheild"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileShield}
                className="text-primary pr-3"
              />
              Sheild 3ANET
            </p>
          </li>
        </NavLink>

        <NavLink to={"shootkoora"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faFutbol} className="text-primary pr-3" />
              Shoot Koora
            </p>
          </li>
        </NavLink>

        <NavLink to={"alsirat"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faMosque} className="text-primary pr-3" />
              Al Sirat
            </p>
          </li>
        </NavLink>

        {/* <NavLink to={'home'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faChartLine}
                                className='text-primary pr-3'
                            />
                            Home
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'categories'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faObjectGroup}
                                className='text-primary pr-3' />
                            Categories
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'widget'} >
                <li className='py-2 '>
                    <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                        <FontAwesomeIcon
                            icon={faObjectGroup}
                            className='text-primary pr-3' />
                        Widget
                    </p>
                </li>
                </NavLink>
                
                <NavLink to={'special'} >
                <li className='py-2 '>
                    <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                        <FontAwesomeIcon
                            icon={faObjectGroup}
                            className='text-primary pr-3' />
                        Special
                    </p>
                </li>
                </NavLink>
                <NavLink to={'sub-categories'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faList}
                                className='text-primary pr-3' />
                            Sub Categories
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'store'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faArchway}
                                className='text-primary pr-3' />
                            Stores
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'menu'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faArchway}
                                className='text-primary pr-3' />
                            Stores Categories
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'management'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faArchway}
                                className='text-primary pr-3' />
                            Management
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'items'}>
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faRectangleList}
                                className='text-primary pr-3' />
                            Items
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'offers'}>
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faRectangleList}
                                className='text-primary pr-3' />
                            Offers
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'orders'}>
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faReceipt}
                                className='text-primary pr-3' />
                            Orders
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'complaints'}>
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faUserInjured}
                                className='text-primary pr-3'
                            />
                            Complaints
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'drivers'}>
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faCar}
                                className='text-primary pr-3'
                            />
                            Drivers
                        </p>
                    </li>
                </NavLink>
                <NavLink to={'users'} >
                    <li className='py-2 '>
                        <p className='text-dark pl-3 font-weight-bold pt-1' href='#'>
                            <FontAwesomeIcon
                                icon={faRestroom}
                                className='text-primary pr-3' />
                            App Users
                        </p>
                    </li>
                </NavLink>
                 */}
      </ul>
      <button className="btn btn-dark mb-4 ml-4" onClick={signout}>
        LOGOUT
      </button>
    </div>
  );
};

export default AdminSidebar;
