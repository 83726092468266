import { useState, useEffect } from "react";
import { fetchUrl } from "../../functions/fetchUrl";

const WithShowAddEditDeleteOfAllPages = (WrappedComponent, endPointName) => {
  const WithShowAddEditDeleteOfAllPages = () => {
    // these state for toggle bwetween add, edit, delete components
    const [showAddBox, setShowAddBox] = useState(false);
    const [showdeleteBox, setShowDeleteBox] = useState(false);
    const [showEditBox, setShowEditBox] = useState(false);

    // for search field in all the component
    const [textOfTableWhenSearching, setTextOfTableWhenSearching] =
      useState("Loading...");
    const [search, setSearch] = useState();
    const [resultSearch, setResultSearch] = useState([]);

    // to set search state
    const setSearchFun = (text) => {
      setSearch(text);
    };
    // for search when the search field is not empty
    useEffect(() => {
      if (search) {
        const stringConvertedTOUpperCase = search.toUpperCase();
        var matches = dataOfTable.filter((s) => {
          //    console.log("DataIs: ", s )
          if (s.name) {
            let fieldConvertedTOUpperCase = s.name.toUpperCase();
            if (
              fieldConvertedTOUpperCase.includes(stringConvertedTOUpperCase)
            ) {
              return fieldConvertedTOUpperCase.includes(
                stringConvertedTOUpperCase
              );
            } else if (s.postbackurl) {
              let fieldConvertedTOUpperCase = s.postbackurl.toUpperCase();
              if (
                fieldConvertedTOUpperCase.includes(stringConvertedTOUpperCase)
              ) {
                return fieldConvertedTOUpperCase.includes(
                  stringConvertedTOUpperCase
                );
              } else {
                let fieldConvertedTOUpperCase = s.shortname.toUpperCase();
                return fieldConvertedTOUpperCase.includes(
                  stringConvertedTOUpperCase
                );
              }
            }
          } else if (s.service) {
            let fieldConvertedTOUpperCase = s.service.toUpperCase();
            return fieldConvertedTOUpperCase.includes(
              stringConvertedTOUpperCase
            );
          } else if (s.servicename) {
            let fieldConvertedTOUpperCase = s.servicename.toUpperCase();
            return fieldConvertedTOUpperCase.includes(
              stringConvertedTOUpperCase
            );
          }

          // return fieldConvertedTOUpperCase.includes(stringConvertedTOUpperCase);
        });

        setResultSearch(matches);
        if (matches.length !== 0) {
          setTextOfTableWhenSearching("Loading ...");
        } else {
          setTextOfTableWhenSearching("No Data");
        }
      } else {
        setResultSearch([]);
      }
    }, [search]);

    const [dataOfTable, setDataOfTable] = useState([]);
    useEffect(async () => {
      const data = await fetchUrl({ apiName: endPointName, method: "get" });
      if (data.success) {
        setDataOfTable(data.result);
      } else {
        console.log(data.result);
      }
    }, [showAddBox, showdeleteBox, showEditBox]);

    const showAddBoxFun = () => {
      setShowAddBox(!showAddBox);
    };

    const showDeleteBoxFun = () => {
      setShowDeleteBox(!showdeleteBox);
    };

    const showEditBoxFun = () => {
      setShowEditBox(!showEditBox);
    };
    return (
      <WrappedComponent
        showAddBox={showAddBox}
        showdeleteBox={showdeleteBox}
        showEditBox={showEditBox}
        showAddBoxFun={showAddBoxFun}
        showDeleteBoxFun={showDeleteBoxFun}
        showEditBoxFun={showEditBoxFun}
        dataOfTable={dataOfTable}
        textOfTableWhenSearching={textOfTableWhenSearching}
        resultSearch={resultSearch}
        setSearchFun={setSearchFun}
        search={search}
      />
    );
  };
  return WithShowAddEditDeleteOfAllPages;
};

export default WithShowAddEditDeleteOfAllPages;
