import { useRef, useState } from 'react'
import fetchURLForm from '../../functions/fetchURLForm'
import logo from './../../images/mmt.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const NewPassword = ({
  data
}) => {
  const [alert, setAlert] = useState(false)
  const [showOpenEyeIcon, setShowOpenEyeIcon] = useState(false)
  const [showOpenEyeIconConfirm, setShowOpenEyeIconConfirm] = useState(false)

  const password = useRef()
  const Confirmpassword = useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password.current.value !== Confirmpassword.current.value) {
      setAlert(true)
    } else if (password.current.value && Confirmpassword.current.value) {
      const body = {
        id: data.id,
        password: password.current.value
      }
      // console.log("body", body)
      const response = await fetchURLForm(
        /*url = */`forgetpassword/updatepassword`,
        /*method = */`POST`,
        /*data = */ body
      )
      // console.log(response)
      window.location = "http://localhost:3000"
    }
  }

  const hideAlert = () => {
    setAlert(false)
  }
  const handleClickIconEye = () => {
    const input = document.querySelector('#password')
    if(input.type === 'password'){
      setShowOpenEyeIcon(true)
      input.type = 'text'
    }else{
      setShowOpenEyeIcon(false)
      input.type = 'password'
    }
   
  }

  const handleClickIconEyeConfirm = () => {
    const confirmPassword = document.querySelector('#confirmPassword')
    
    if(confirmPassword.type === 'password'){
      setShowOpenEyeIconConfirm(true)
      confirmPassword.type = 'text'
    }else{
      setShowOpenEyeIconConfirm(false)
      confirmPassword.type = 'password'
    }
  }
  return (
    <>
      {/* <img className='logo' src={logo} /> */}
      <div id="login-form-wrap">
        <div className='d-flex justify-content-center p-3'>
          <img className='logo' src={logo} />
        </div>
        {
          alert && <div className="alert alert-danger" role="alert">
            The fields does not mateched, try again
          </div>
        }
        <div>
          <p>Set New Password</p>
        </div>
        <form id="login-form" onSubmit={handleSubmit}>
          <div>
            <FontAwesomeIcon
              icon={
                showOpenEyeIcon ? faEye : faEyeSlash
              }
              className='eye-icon'
              onClick={handleClickIconEye}
            />
            <input
              ref={password}
              type="password"
              id="password"
              name="password"
              placeholder="Enter Your Password"
              required
              onChange={hideAlert}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={
                showOpenEyeIconConfirm ? faEye : faEyeSlash
              }
              className='eye-icon'
              onClick={handleClickIconEyeConfirm}
            />
            <input
              ref={Confirmpassword}
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              required
              onChange={hideAlert}
            />
          </div>
          <div>
            <button
              className='mb-5 login-button'
              type="submit"
              id="login"
            > Set New Password
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default NewPassword