import { createStyles, makeStyles, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from "react";
import { fetchUrl } from "../../../../functions/fetchUrl";

const StoreCombobox = ({
    
    setstoreIdFun
}) => {
    
    const [selectedItem, setSelectedItem] = useState('')
    const [dataOfTable, setDataOfTable] = useState([])
    useEffect( async() => {
        const data = await fetchUrl({ apiName: 'store', method: 'get' })
        if (data.success) {
            setDataOfTable(data.result)
            // console.log("Category: ", data.result)
        } else {
            console.log(data.result)
        }
    }, [])
    
    useEffect(() => {
        if (selectedItem) {
            let widgetDetails = selectedItem.split(':')
            let idWidget = widgetDetails[0]
            // alert(idWidget)
            setstoreIdFun(idWidget)
        }
    }, [selectedItem])


    return (
        <Autocomplete
            id="combo-box-demo"
            
            options={dataOfTable}
            getOptionLabel={option => `${option.id.toString()}:${option.name.toString()}`}
            renderInput={(params) => {
                // console.log(params)
                return <TextField {...params} label="Choose Store Name:" required/>

            }}
            onInputChange={(e, value) => { setSelectedItem(value) }}

        />
    )
}

export default StoreCombobox