import { DataGrid } from "@mui/x-data-grid";
import { postedColumns } from "../datatablesource";
import { useEffect, useState } from "react";

import { fetchUrl } from "../../../functions/fetchUrl";

const PostedNonBillable = ({ table }) => {
  useState("Loading...");

  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    const fetchData = async () => {
      const response = await fetchUrl({ apiName: table, method: "get" });
      if (response.success) {
        setData(response.result);
      }
    };
    fetchData();
  }, [table]);

  return (
    <div>
      <div>
        <div>
          <DataGrid
            className="ml-1"
            rows={data}
            columns={postedColumns}
            pageSize={15}
            rowsPerPageOptions={[5]}
            style={{ height: "60vh", marginLeft: "50px" }}
            getRowId={(data) => data.id}
          />
        </div>
      </div>
    </div>
  );
};

export default PostedNonBillable;
