import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className='col-10 mt-5' >
            <div className='text-center text-danger mt-5'>
                <h4>This Pages Does Not Exist</h4>
                <button
                    className='btn btn-success'
                    onClick={() => navigate(-1)}>
                    Go To Previous Pages...
                </button>
            </div>
        </div>
    )
}

export default NotFound