// require('dotenv').config();
// let HOST_URL = process.env.REACT_APP_HOST_URL;

import { getCookie, removeCookie } from "./cookie";

/**
 * Used to make it easy to fetch from backend
 * @param {string}
 * @returns
 */
export async function fetchUrl({ method, apiName, reqBody }) {
  let data = {};
  let url = "https://dashboard.mobimartech.com:3456/" + apiName;

  let id = getCookie("id");
  let token = getCookie("token");
  let header = { id, token };

  let headers = {
    ...header,
  };

  if (reqBody)
    headers = {
      "Content-Type": "application/json",
      ...header,
    };

  let requests = {
    method,
    headers,
    body: JSON.stringify(reqBody),
  };

  removeCookie(id);
  let response = await fetch(url, requests);
  data = await response.json();
  // console.log(data)
  return data;
}
