import axios from "axios";
import { getCookie } from "./cookie";


const fetchURLForm = async (url, method, data) => {
    let response;
    let id = getCookie('id');
    let token = getCookie('token');

    let header = { id, token }
    await axios({
        url,
        method,
        headers: {
            header
        },
        data,
    })
        .then(res => {
            // console.log("first",res)
            response = res
        })
        .catch(error => {
            console.log(error)
            response = error
        })
        // console.log(response)
        return response
}

export default fetchURLForm