import React, { useRef, useState } from "react";
import fetchURLForm from "../../../functions/fetchURLForm";

const EditAdvertisingNetwork = ({ data, showEditingStoreBox }) => {
  const name = useRef("");
  const shortname = useRef("");
  const postbackurl = useRef("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // to set imageWidget state as an image

  const handleSave = async (event) => {
    event.preventDefault();
    const body = new FormData();

    body.append("name", name.current.value);
    body.append("shortname", shortname.current.value);
    body.append("postbackurl", postbackurl.current.value);

    const response = await fetchURLForm(
      /*url = */ `services/updateadvertisingnetwork/${data.id}`,
      /*method = */ "PUT",
      /*data = */ body
    );
    if (response.data.success) {
      setSuccess(true);
      setTimeout(() => {
        showEditingStoreBox();
      }, 300);
    } else {
      setError(true);
      console.log(response);
    }
  };
  const hideAlert = () => {
    setSuccess(false);
    setError(false);
  };
  return (
    <div
      id="addNoteModal"
      className="modal"
      style={{
        zIndex: "99999",
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>
            Edit Service <span className="text-warning">{data.name}</span>{" "}
          </h2>
        </div>
        <div>
          {success && (
            <div className="alert alert-success" role="alert">
              Edited Successfully...
            </div>
          )}
          {error && (
            <div className="alert alert-anger" role="alert">
              Edited Failed Try Again...
            </div>
          )}
        </div>
        <form
          className="w-100 p-5"
          encType="multipart/form-data"
          onSubmit={handleSave}
        >
          <div className="mt-2">
            <label htmlFor="name">Network Name</label>
            <input
              ref={name}
              type="text"
              id="name"
              placeholder="Network Name"
              className="form-control"
              required
              defaultValue={data.name}
              onChange={hideAlert}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="shortname">Short Name</label>
            <input
              ref={shortname}
              type="text"
              id="shortname"
              placeholder="shortname"
              className="form-control"
              required
              defaultValue={data.shortname}
              onChange={hideAlert}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="postbackurl">Posted Back URL</label>
            <input
              ref={postbackurl}
              type="text"
              id="postbackurl"
              placeholder="postbackurl"
              className="form-control"
              required
              defaultValue={data.postbackurl}
              onChange={hideAlert}
            />
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={showEditingStoreBox}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAdvertisingNetwork;
