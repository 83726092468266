import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
Chart.register(...registerables);

const ChartLine = ({
    data
}) => {
    // console.log('data', data)
    const [total, setTotal] = useState([])
    const [pending, setPending] = useState([])
    const [solved, setSolved] = useState([])
    const [days, setDays] = useState([])
    // console.log('daus',days)
    useEffect(() => {
        // console.log(data[0].length)
        if (data[1].length > 0) {
            const total = data[0].map(element => {
                return element.total
            })
            setTotal(total.reverse())

            const pending = data[1].map(element => {
                return element.total
            })
            setPending(pending.reverse())

            const solved = data[2].map(element => {
                return element.total
            })
            setSolved(solved.reverse())
            const days = data[2].map(element => {
                return element.nameDay
            })
            setDays(days.reverse())
        }
    }, [data])

    return (

        <Line data={
            {
                labels: days ? days : ['day', 'day ', 'day ', 'day ', 'day', 'day ', 'day '],
                datasets: [{
                    label: "Total Numbers Of Complaints",
                    data: total ? total : [1, 1, 1, 1, 1, 1, 1],
                    fill: true,
                    borderColor: '#f00',
                    tension: 0.1
                },
                {
                    label: 'Pending Requests',
                    data: pending ? pending : [1, 1, 1, 1, 1, 1, 1],
                    fill: true,
                    borderColor: '#FFBB00',
                    tension: 0.1
                },
                {
                    label: 'Solved Requests',
                    data: solved ? solved : [1, 1, 1, 1, 1, 1, 1],
                    fill: true,
                    borderColor: '#0f0',
                    tension: 0.1
                }],

            }
        }
            width={350}
            height={50}
            options={{
                plugins: {
                    legend: {
                        display: false,
                    }
                },
            }}
        />
    )
}

export default ChartLine