import { useRef, useState } from 'react'
import fetchURLForm from '../../../functions/fetchURLForm'
import './add-widget.css'
const AddWidget = ({ showAddBoxFun }) => {
    const nameWidget = useRef('')
    // const descriptionWidget = useRef('')
    const [imageWidget, setImageWidget] = useState(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    // to set imageWidget state as an image
    const fileSelectedHandler = event => {
        setImageWidget(event.target.files[0])
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const body = new FormData()
        body.append("files", imageWidget);
        // body.append("description", descriptionWidget.current.value);
        body.append("name", nameWidget.current.value);
        const response = await fetchURLForm(/*url = */'category',/*method = */'POST',/*data = */ body)
        if(response.data.success){
            setSuccess(true)
        }else{
            setError(true)
            console.log(response)
        }
    }

    const hideAlert = () => {
        setSuccess(false)
        setError(false)
    }

    return (
        <div id="addNoteModal" className="modal" style={{
            zIndex:'99999'

          }}>

            <div className="modal-content">
                <div className="modal-header">
                    <h2>New Category</h2>
                </div>
                <div>
                    {
                        success && <div className="alert alert-success" role="alert">
                            Created Successfully...
                        </div>
                    }
                    {
                        error && <div className="alert alert-danger" role="alert">
                            Created Failed Try Again...
                        </div>
                    }
                </div>
                <form
                    className='w-100 p-5'
                    encType="multipart/form-data"
                    onSubmit={handleSave}
                >
                    <div className='mt-2'>
                        <label htmlFor="nameWidget">Category Name</label>
                        <input
                            ref={nameWidget}
                            type='text'
                            id="nameWidget"
                            placeholder='Name Of Category'
                            className='form-control'
                            required
                            onChange={hideAlert}
                        />
                    </div>
                    {/* <div className='mt-2'>
                        <label htmlFor="description">Description Ad</label>
                        <input
                            ref={descriptionWidget}
                            type='text'
                            id="description"
                            placeholder='Description'
                            className='form-control'
                            required
                            onChange={hideAlert}
                        />
                    </div> */}
                    <div className='mt-2'>
                        <label htmlFor="image">Image</label>
                        <input
                            type='file'
                            id="image"
                            className='form-control'
                            required
                            onChange={fileSelectedHandler}
                        />
                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={showAddBoxFun}
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddWidget