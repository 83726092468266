import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import DeleteBox from "../../delete-box/DeleteBox";
import WithShowAddEditDeleteOfAllPages from "../../HOC/WithShowAddEditDeleteOfAllPages";
import AddMenu from "./AddMenu";
import EditMenu from "./EditMenu";
import { createStyles, makeStyles, TextField } from "@material-ui/core";

const StoresCategories = ({
  showAddBox,
  showdeleteBox,
  showEditBox,
  showAddBoxFun,
  showDeleteBoxFun,
  showEditBoxFun,
  dataOfTable,
  textOfTableWhenSearching,
  resultSearch,
  setSearchFun,
  search,
}) => {
  const useStyles = makeStyles({
    dataGrid: {
      width: "440px",
    },
  });
  const classes = useStyles();

  const URLImage = "https://dashboard.mobimartech.com:3456/images/";
  const [dataSendingToDeleteBox, setDataSendingToDeleteBox] = useState({});
  const [dataSendingToEditBox, setDataSendingToEditBox] = useState({});
  // const [search, setSearch] = useState('')
  // const [resultSearch, setResultSearch] = useState([])
  // const [textOfTableWhenSearching, setTextOfTableWhenSearching] = useState('Loading...')
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a button edit and delete user.",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-info"
              onClick={() => {
                setDataSendingToEditBox(params.row);
                showEditBoxFun();
              }}
            >
              Edit
            </button>
            <FontAwesomeIcon
              className="text-danger icon-delete ml-3"
              icon={faTrash}
              onClick={() => {
                setDataSendingToDeleteBox(params.row);
                showDeleteBoxFun();
              }}
            />
          </div>
        );
      },
    },
  ];
  // const rows = [
  //     { id: 1, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=171', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 2, photo: 'https://tse4.mm.bing.net/th?id=OIP.PYipJ_hSncugM2SwnZitvgHaEK&pid=Api&P=0&w=306&h=172', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 3, photo: 'https://tse2.mm.bing.net/th?id=OIP.3R3_z2zCLOG4yGyzcxeD8wHaEo&pid=Api&P=0&w=245&h=153', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 4, photo: 'https://www.pcclean.io/wp-content/gallery/birds/331335.jpg', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 5, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=176', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 6, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=177', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 7, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=178', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 8, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=179', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 9, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=174', name: 'something ...', description: 'some description', price: 15 },
  //     { id: 10, photo: 'https://tse2.mm.bing.net/th?id=OIP.avxhtQqr5FoubGjq4sIXugHaEo&pid=Api&P=0&w=275&h=171', name: 'something ...', description: 'some description', price: 15 },

  // ];
  const rows = search ? resultSearch : dataOfTable;
  return (
    <div
      className="col-10"
      style={{
        position: "relative",
        left: "220px",
        top: "100px",
      }}
    >
      {showAddBox ? (
        <AddMenu showAddBoxFun={showAddBoxFun} />
      ) : showdeleteBox ? (
        <DeleteBox
          APIName={"storescategory"}
          id={dataSendingToDeleteBox.id}
          closeBox={showDeleteBoxFun}
          name={"Menu"}
          data={dataSendingToDeleteBox.name}
        />
      ) : showEditBox ? (
        <EditMenu showEditBoxFun={showEditBoxFun} data={dataSendingToEditBox} />
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-4 ">
            <h2 className="text-secondary font-weight-bold">
              Stores Categories:
            </h2>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search ..."
              aria-label="Search"
              onChange={(e) => setSearchFun(e.target.value)}
            />

            <button className="btn btn-primary" onClick={showAddBoxFun}>
              Add
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="m-5">
              <DataGrid
                className={classes.dataGrid}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                style={{ height: "60vh" }}
                components={{
                  NoRowsOverlay: () => (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <h3>{textOfTableWhenSearching}</h3>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithShowAddEditDeleteOfAllPages(
  StoresCategories,
  "storescategory"
);
