import React, { useState, useEffect } from "react";
import SessionContext from "./SessionContext";
import { setCookie, getCookie, removeCookie } from "../../functions/cookie";
import { fetchUrl } from "../../functions/fetchUrl";
// import { toast } from "react-toastify";

export default function SessionProvider({ children }) {
  const [session, updateSession] = useState({
    user: {
      id: getCookie("id"),
      token: getCookie("token"),
      wrongemailOrPassword: false,
    },
  });

  const [role, setRole] = useState("");
  const [Username, setUserName] = useState("");
  const [imageUser, setimageUser] = useState("");

  function setSession(nextSession) {
    updateSession((prevSession) => ({
      ...prevSession,
      ...nextSession,
    }));
  }

  async function signin({ email, password }) {
    // console.log('sessop',role);
    let reqBody = { email, password };
    let response = await fetchUrl({
      method: "post",
      apiName: "admin/signin",
      reqBody,
    });

    if (response) {
      let user = response;
      setCookie("id", user.id, 30);
      setCookie("token", user.token, 30);
      setSession({ user });
      setRole(user.role);
      setUserName(user.name);
      setimageUser(user.image);
      // console.log("ROle Session: " ,user.role);
      // console.log("setimageUser: " ,user);
    } else {
      setSession({ user: { wrongemailOrPassword: true } });
    }
  }

  async function signup(reqBody) {
    let response = await fetchUrl({
      method: "POST",
      apiName: "signup",
      reqBody,
    });
    // setRole('')
    return response;
  }

  async function signout() {
    let {
      user: { id, token },
    } = session;
    removeCookie("id");
    removeCookie("token");
    setRole("");
    setUserName("");
    setimageUser("");
    setSession({ user: {} });
  }
  let token = session.user.token;
  useEffect(() => {
    async function initializeUser() {
      let response = await fetchUrl({
        apiName: `initialize/${session.user.id}/${session.user.token}`,
        method: "get",
      });
      // console.log(response.result[0]);
      let user = response.result[0];
      setRole(user.role);
      setUserName(user.name);
      setimageUser(user.image);
      // console.log('session',user)
    }

    initializeUser();
  }, [session.user.id, session.user.token, token]);

  let context = {
    role,
    Username,
    imageUser,
    session,
    actions: { signin, signout, signup, setSession },
  };

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
}
