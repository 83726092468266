import ChartCircle from './charts/ChartCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import './home.css'
import CustomerServices from './customer_services/CustomerServices'
import TopRestaurants from './top_restaurants/TopRestaurants'
import RecommendationRestaurants from './recommendation_restaurants/WorstRestaurants'
import { useEffect, useState } from 'react'
import fetchUrlForm from './../../../functions/fetchURLForm'
import { useRadioGroup } from '@material-ui/core'
const Home = () => {
  const [dataOfTablesRestaurant, setDataOfTablesRestaurant] = useState({
    succcess: true,
    origin: {
      Top: [],
      Worst: []
    }
  })
  const [restaurantData, setRestaurantData] = useState({
    succcess: true,
    total: 0,
    last7: {
      origins: [],
      total: 0
    }
  })
  const [driversData, setDriversData] = useState({
    succcess: true,
    total: 0,
    last7: {
      drivers: [],
      total: 0
    }
  })
  const [usersData, setUsersData] = useState({
    succcess: true,
    total: 0,
    last7: {
      drivers: [],
      total: 0
    }
  })
  const [orderData, setOrderData] = useState({
    succcess: true,
    orders: {
      total: 0,
      totalCanceled: 0,
      totalDelivered: 0
    }
  })
  // https://dashboard.mobimartech.com:3456/user/getuser/tracking
  // https://dashboard.mobimartech.com:3456/drivers/getdrivers/tracking
  // https://dashboard.mobimartech.com:3456/origin/origintracking/count
  useEffect(async () => {
    // for top and worst restaurants 
    const response5 = await fetchUrlForm(
  /*url = */'store/getOrigin/top',
  /*method = */'GET',
    )
    if (response5.data.succcess) {
      setDataOfTablesRestaurant(response5.data)
      // console.log(response5.data)
    } else {
      console.log(response5.data.success)
    }
    // for restaurant chart 
    const response1 = await fetchUrlForm(
      /*url = */'store/origintracking/count',
      /*method = */'GET',
    )
    if (response1.data.succcess) {
      setRestaurantData(response1.data)
    } else {
      console.log(response1)
    }

    // for drivers chart 
    const response2 = await fetchUrlForm(
      /*url = */'drivers/getdrivers/tracking',
      /*method = */'GET',
    )
    if (response2.data.succcess) {
      setDriversData(response2.data)
    } else {
      console.log(response2)
    }

    // for users chart 
    const response3 = await fetchUrlForm(
      /*url = */'user/getuser/tracking',
      /*method = */'GET',
    )
    if (response3.data.succcess) {
      setUsersData(response3.data)
    } else {
      console.log(response3)
    }

    // for orders chart 
    const response4 = await fetchUrlForm(
      /*url = */'order/getorder/tracking',
      /*method = */'GET',
    )
   
    if (response4.data.success) {
      setOrderData(response4.data)
    } else {
      console.log(response4.data.success)
    }


  }, [])

  return (
    <div className='col-10 mt-3' style={{
      position: 'relative',
      left: '250px',
      top: '100px',
    }}>
      <h2 className="text-secondary font-weight-bold" >Home</h2>
      {/* first row  */}
      <div className='row'>
        {/* Restaurants card  */}
        <div className='col-3'>
          <div className='shadow parent-chart p-3'>
            <div >
              <span className='font-weight-bold'><u>Restaurants:</u></span>
              <div className=''>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='icon-color-total'
                  />
                  <small className='pl-2'>Total Restaurants</small>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='icon-color-7'
                  />
                  <small className='pl-2'>Last 7 Days</small>
                </div>
              </div>
            </div>
            <div className='p-3'>
              {/* {
                {total, last7} = restaurantData
              } */}
              <ChartCircle
                chartLabel={['Total Restaurants']}
                nameOfChart={'Restaurants'}
                NumberOfTotal={[restaurantData.total > 0 ? restaurantData.total : 1]}
                colorOfTotal={['rgb(54, 162, 235)']}
              />
            </div>
            <div className='child-chart'>
              <ChartCircle
                chartLabel={['Last 7 Days']}
                nameOfChart={'Resturants'}
                NumberOfTotal={[restaurantData.last7.total > 0 ? restaurantData.last7.total : 1]}
                colorOfTotal={['rgb(255, 99, 132)']}
              />
            </div>
          </div>
        </div>
        {/* restaurant part  */}
        <div className='col-9 shadow parent-chart'>
          <div className='row'>
            <div className='col-6 '>
              <TopRestaurants data={dataOfTablesRestaurant.origin.Top} />
            </div>
            <div className='col-6'>
              <RecommendationRestaurants data={dataOfTablesRestaurant.origin.Worst} />
            </div>
          </div>
        </div>
      </div>
      {/* second row */}
      <div className='row mt-4'>
        {/* Users card  */}
        <div className='col-4'>
          <div className='shadow parent-chart p-3'>
            <div >
              <span className='font-weight-bold'><u>Users:</u></span>
              <div className=''>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='icon-color-total'
                  />
                  <small className='pl-2'>Total Users</small>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='icon-color-7'
                  />
                  <small className='pl-2'>Last 7 Days</small>
                </div>
              </div>
            </div>
            <div className='p-3'>
              <ChartCircle
                chartLabel={['Total Users']}
                nameOfChart={'Users'}
                NumberOfTotal={[usersData.total > 0 ? usersData.total : 1]}
                colorOfTotal={['rgb(54, 162, 235)']}
              />
            </div>
            <div className='child-chart'>
              <ChartCircle
                chartLabel={['Last 7 Days']}
                nameOfChart={'Users'}
                NumberOfTotal={[usersData.last7.total > 0 ? usersData.last7.total : 1]}
                colorOfTotal={['rgb(255, 99, 132)']}
              />
            </div>
          </div>
        </div>
        {/* Drivers card  */}
        <div className='col-4'>
          <div className='shadow parent-chart p-3'>
            <div >
              <span className='font-weight-bold'><u>Drivers:</u></span>
              <div className=''>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='icon-color-total'
                  />
                  <small className='pl-2'>Total Drivers</small>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='icon-color-7'
                  />
                  <small className='pl-2'>Last 7 Days</small>
                </div>
              </div>
            </div>
            <div className='p-3'>
              <ChartCircle
                chartLabel={['Total Drivers']}
                nameOfChart={'Driver'}
                NumberOfTotal={[driversData.total > 0 ? driversData.total : 1]}
                colorOfTotal={['rgb(54, 162, 235)']}
              />
            </div>
            <div className='child-chart'>
              <ChartCircle
                chartLabel={['Last 7 Days']}
                nameOfChart={'Drivers'}
                NumberOfTotal={[driversData.total > 0 ? driversData.total : 1]}
                colorOfTotal={['rgb(255, 99, 132)']}
              />
            </div>
          </div>
        </div>
        {/* Orders card  */}
        <div className='col-4'>
          <div className='shadow parent-chart p-3'>
            <div >
              <span className='font-weight-bold'><u>Orders:</u></span>
              <div className='d-flex justify-content-betweenalign-items-center'>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='pl-1'
                    style={{ color: 'rgb(54, 162, 235)' }}
                  />
                  <small>Today</small>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='pl-1'
                    style={{ color: '#0f0' }}
                  />
                  <small>Delivered</small>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    className='pl-1'
                    style={{ color: '#f00' }}
                  />
                  <small>Canceled</small>
                </div>
              </div>
            </div>
            <div className='p-3 mt-4'>
              <ChartCircle
                chartLabel={[
                  'Total Number Daily', 'Delivered Orders', 'Canceled Orders'
                ]}
                nameOfChart={'Users'}
                NumberOfTotal={[
                  orderData.orders.total > 0 ? orderData.orders.total : 1,
                  orderData.orders.totalCanceled > 0 ? orderData.orders.totalCanceled : 1,
                  orderData.orders.totalDelivered > 0 ? orderData.orders.totalDelivered : 1,
                ]}
                colorOfTotal={['rgb(54, 162, 235)', '#0f0', '#f00']}
              />
            </div>
          </div>
        </div>

      </div>
      {/* customer services */}
      <div className='row'>
        <div className='col-12 py-4'>
          <CustomerServices />
        </div>
      </div>
    </div>

  )
}

export default Home