import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { allServicesPostedColumns } from "../datatablesource";
import { useEffect, useState } from "react";

// import { fetchUrl } from "../../../../functions/fetchUrl";
import fetchURLForm from "../../../functions/fetchURLForm";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "@mui/joy/Input";
import Box from "@mui/material/Box";
import numeral from "numeral";

const AllPosted = ({ table }) => {
  useState("Loading...");
  const today = new Date();

  var fileDate =
    today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();

  var fileName = `AllPosted_${fileDate}`;

  // if (table === "services/allbillablepostedtotal") {
  //   fileName = `AdvertisingBillablePosted_${fileDate}`;
  // } else if (table === "services/allnonbillablepostedtotal") {
  //   fileName = `AdvertisingNonBillablePosted_${fileDate}`;
  // }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ allColumns: true, fileName }} />
      </GridToolbarContainer>
    );
  }

  const [data, setData] = useState([]);
  const [valueDateFrom, setValueDateFrom] = useState(today);
  const [valueDateTo, setValueDateTo] = useState(today);
  const [requestText, setRequestText] = useState(
    "alsirat,shield,shootkoora,oxford,islamicnote,gombsgom,petcare,safensecure,extratime,securensafe,mobofort,fileshield,filesguard,vipgym,whatsfile,smsimapp,koraclub,dailybeauty,kidspreschool,islamnquran,islamicreminder,bnfootball,mobileboxes,chefrecipe,gamesportal,muslimsgate,gymnslim"
  );

  useEffect(() => {
    setData([]);
    //   "valueDateTo": "01-20-2023"

    const monthFromNumber = (valueDateFrom.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayFromNumber = valueDateFrom.getDate().toString().padStart(2, "0");
    const yearFromNumber = valueDateFrom.getFullYear();

    const monthToNumber = (valueDateTo.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayToNumber = valueDateTo.getDate().toString().padStart(2, "0");
    const yearToNumber = valueDateTo.getFullYear();

    const dateFrom =
      monthFromNumber + "-" + dayFromNumber + "-" + yearFromNumber;

    const dateTo = monthToNumber + "-" + dayToNumber + "-" + yearToNumber;

    const bodySend = {
      valueDate: dateFrom,
      valueToDate: dateTo,
      requestText: requestText,
    };

    const fetchData = async () => {
      const response = await fetchURLForm(
        /*url = */ `${table}`,
        /*method = */ "POST",
        /*data = */ bodySend
      );
      if (response.data.success) {
        setData(response.data.result);
        // setTotal(response.data.result.Total);
      }
    };
    fetchData();
  }, [requestText, table, valueDateFrom, valueDateTo]);

  const dataWithId = data.map((obj) => {
    return {
      ...obj,
      id: Math.floor(Math.random() * 1000000),
    };
  });
  return (
    <div>
      <div>
        <div>
          <div>
            <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From:"
                    value={valueDateFrom}
                    onChange={(newValue) => {
                      setValueDateFrom(new Date(newValue));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To:"
                    value={valueDateTo}
                    onChange={(newValue) => {
                      setValueDateTo(new Date(newValue));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </Box>

            <div>
              <DataGrid
                className="ml-1"
                rows={dataWithId}
                columns={allServicesPostedColumns}
                style={{ height: "75vh", marginLeft: "50px" }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                getRowId={(data) => data.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPosted;
