import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import AddService from "./AddService";
import EditService from "./EditService";
import DeleteBox from "../../delete-box/DeleteBox";
import WithShowAddEditDeleteOfAllPages from "../../HOC/WithShowAddEditDeleteOfAllPages";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { servicesColumns } from "../datatablesource";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAlert } from "react-alert";

const Services = ({
  showAddBox,
  showdeleteBox,
  showEditBox,
  showAddBoxFun,
  showDeleteBoxFun,
  showEditBoxFun,
  dataOfTable,
  textOfTableWhenSearching,
  resultSearch,
  setSearchFun,
  search,
}) => {
  const useStyles = makeStyles({
    dataGrid: {
      width: "1100px",
    },
  });
  const classes = useStyles();

  const alert = useAlert();

  const [dataSendingToDeleteBox, setDataSendingToDeleteBox] = useState({});
  const [dataSendingToEditBox, setDataSendingToEditBox] = useState({});

  const textRef = useRef(null);

  function copyToClipboard(text) {
    alert.success("Copied to clipboard");
    navigator.clipboard.writeText(text);
  }
  const clickableIconStyle = {
    cursor: "pointer",
    marginLeft: 10,
  };
  const tableHeaderStyle = {
    display: "flex",
    alignItems: "center",
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      description: "This column has a button edit and delete user.",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-info"
              onClick={() => {
                setDataSendingToEditBox(params.row);
                showEditBoxFun();
              }}
            >
              Edit
            </button>
            <FontAwesomeIcon
              className="text-danger icon-delete ml-3"
              icon={faTrash}
              onClick={() => {
                setDataSendingToDeleteBox(params.row);
                showDeleteBoxFun();
              }}
            />
          </div>
        );
      },
    },
  ];

  const today = new Date();
  var fileDate =
    today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();

  var fileName = `Services_${fileDate}`;
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ allColumns: true, fileName }} />
      </GridToolbarContainer>
    );
  }

  const rows = search ? resultSearch : dataOfTable;
  return (
    <div
      className="col-10 "
      style={{
        position: "relative",
        left: "220px",
        top: "100px",
      }}
    >
      {showAddBox ? (
        <AddService showAddStoreBox={showAddBoxFun} />
      ) : showdeleteBox ? (
        <DeleteBox
          APIName={"services"}
          name={"Services"}
          id={dataSendingToDeleteBox.id}
          closeBox={showDeleteBoxFun}
          data={dataSendingToDeleteBox.name}
        />
      ) : showEditBox ? (
        <EditService
          showEditingStoreBox={showEditBoxFun}
          data={dataSendingToEditBox}
        />
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-4 ">
            <h2 className="text-secondary font-weight-bold">Landing Pages:</h2>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search ..."
              aria-label="Search"
              onChange={(e) => setSearchFun(e.target.value)}
            />
            <button className="btn btn-primary" onClick={showAddBoxFun}>
              Add
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center ">
            <div className="mt-5 ">
              <DataGrid
                className={classes.dataGrid}
                rows={rows}
                columns={servicesColumns.concat(actionColumn)}
                pageSize={12}
                rowsPerPageOptions={[9]}
                style={{ height: "70vh" }}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: () => (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <h3>{textOfTableWhenSearching}</h3>
                    </div>
                  ),
                }}
              />
            </div>
          </div>

          <div>
            <div
              style={{ display: "inline-flex", marginTop: 50, marginLeft: 30 }}
            >
              <table
                className="table table-striped table-bordered table-hover"
                style={{ width: 200, marginLeft: 10 }}
              >
                <thead>
                  <tr>
                    <th>#1</th>
                    <th style={tableHeaderStyle}>
                      91.192.194.189
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => copyToClipboard("91.192.194.189")}
                        style={clickableIconStyle}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>MuslimsGate</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Islamic Reminder</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>ChefRecipes</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>TeslaVPN</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Islam&Quraan</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Device Finder</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Phone Cleaner</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Sub & Win</td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table table-striped table-bordered table-hover"
                style={{ width: 200, marginLeft: 10 }}
              >
                <thead>
                  <tr>
                    <th>#2</th>
                    <th style={tableHeaderStyle}>
                      91.215.186.112
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => copyToClipboard("91.215.186.112")}
                        style={clickableIconStyle}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>KoraClub</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Games Portal</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Daily Beauty</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Kids Pre</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>bnfootball</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Sticky Note</td>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-striped table-bordered table-hover"
                style={{ width: 200, marginLeft: 10 }}
              >
                <thead>
                  <tr>
                    <th>#3</th>
                    <th style={tableHeaderStyle}>
                      91.215.186.113
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => copyToClipboard("91.215.186.113")}
                        style={clickableIconStyle}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>FileShield</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Mobofort</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>WhatsFile</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>SmsimApp</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Files Gaurd</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Secure & Save</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Converse Pro AI</td>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-striped table-bordered table-hover"
                style={{ width: 200, marginLeft: 10 }}
              >
                <thead>
                  <tr>
                    <th>#4</th>
                    <th style={tableHeaderStyle}>
                      91.215.186.151
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => copyToClipboard("91.215.186.151")}
                        style={clickableIconStyle}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>VIPGYM</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>GymNSlim</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>MobileBoxes</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Oxford</td>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-striped table-bordered table-hover"
                style={{ width: 200, marginLeft: 10 }}
              >
                <thead>
                  <tr>
                    <th>#5</th>
                    <th style={tableHeaderStyle}>
                      91.192.194.48
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => copyToClipboard("91.192.194.48")}
                        style={clickableIconStyle}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>ExtraTime365</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>GomBsGom</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>IslamicNote</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>PetCare</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Al Sirat</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Shoot Koora</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithShowAddEditDeleteOfAllPages(Services, "services");
