import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AddWidget from "./AddWidget";
import EditWidget from "./EditWidget";
import DeleteBox from "../../delete-box/DeleteBox";
import WithShowAddEditDeleteOfAllPages from "../../HOC/WithShowAddEditDeleteOfAllPages";
import { createStyles, makeStyles, TextField } from "@material-ui/core";

const Categories = ({
  showAddBox,
  showdeleteBox,
  showEditBox,
  showAddBoxFun,
  showDeleteBoxFun,
  showEditBoxFun,
  dataOfTable,
  textOfTableWhenSearching,
  resultSearch,
  setSearchFun,
  search,
}) => {
  const useStyles = makeStyles({
    dataGrid: {
      width: "590px",
    },
  });
  const classes = useStyles();

  // const [showEditWidget, setShowEditWidget] = useState(false)
  const [dataOfEditingWillSend, setDataOfEditingWillSend] = useState({});

  // // this state for send ID to deleteBox handle with delete
  const [dataOfDeleteWillSend, setDataOfDeleteWillSend] = useState("");
  // console.log("dataOfTable", dataOfTable)

  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true },
    {
      field: "name",
      headerName: "Name",
      width: 190,
      sortable: false,
    },
    {
      field: "view",
      headerName: "View",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Link to={params.row.id.toString()}>
              <button className="btn btn-info">View</button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a button edit and delete user.",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-info"
              onClick={() => {
                setDataOfEditingWillSend(params.row);
                showEditBoxFun();
              }}
            >
              Edit
            </button>
            <FontAwesomeIcon
              className="text-danger icon-delete ml-3"
              icon={faTrash}
              onClick={() => {
                showDeleteBoxFun();
                setDataOfDeleteWillSend(params.row);
              }}
            />
          </div>
        );
      },
    },
  ];

  // the rows of table data
  const rows = search ? resultSearch : dataOfTable;

  return (
    <div
      className="col-10 "
      style={{
        position: "relative",
        left: "220px",
        top: "100px",
      }}
    >
      {showAddBox ? (
        <AddWidget showAddBoxFun={showAddBoxFun} />
      ) : showdeleteBox ? (
        <DeleteBox
          closeBox={showDeleteBoxFun}
          name={"Category"}
          data={dataOfDeleteWillSend.name}
          APIName={"category"}
          id={dataOfDeleteWillSend.id}
        />
      ) : showEditBox ? (
        <EditWidget
          data={dataOfEditingWillSend}
          showEditBoxFun={showEditBoxFun}
        />
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center pt-4 ">
            <h2 className="text-secondary font-weight-bold">Categories:</h2>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search ..."
              aria-label="Search"
              onChange={(e) => setSearchFun(e.target.value)}
            />
            <button className="btn btn-primary" onClick={showAddBoxFun}>
              Add
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="mt-5">
              <DataGrid
                className={classes.dataGrid}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                style={{ height: "60vh" }}
                components={{
                  NoRowsOverlay: () => (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <h3>{textOfTableWhenSearching}</h3>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithShowAddEditDeleteOfAllPages(
  Categories,
  /*endpoint = */ "category"
);
