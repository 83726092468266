import React, { useRef, useState } from 'react'
import { fetchUrl } from '../../../functions/fetchUrl'
import  fetchURLForm  from '../../../functions/fetchURLForm'

const AddMenu = ({
    showAddBoxFun
}) => {
    const name = useRef('')
    
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const handleSave = async(e) => {
        e.preventDefault()
        const body = {
            name:  name.current.value
        }
        
        const response = await fetchURLForm(
            /*url = */`storescategory`,
            /*method = */'POST',
            /*data = */ body
          )
        if(response.data.success){
            setSuccess(true)
        }else{
            setError(true)
            console.log(response)
        }
    }
    const hideAlert = ()=>{
        setSuccess(false)
        setError(false)
    }
    return (
        <div id="addNoteModal" className="modal" style={{
            zIndex:'99999'

          }}>

            <div className="modal-content">
                <div className="modal-header">
                    <h2>New Menu</h2>
                </div>
                <div>
                    {
                        success && <div className="alert alert-success" role="alert">

                            Created Successfully...
                        </div>
                    }
                    {
                        error && <div className="alert alert-danger" role="alert">
                            Created Failed Try Again...
                        </div>
                    }
                </div>
                <form
                    className='w-100 p-5'
                    encType="multipart/form-data"
                    onSubmit={handleSave}

                >
                    <div >
                        <label htmlFor="nameItems">Menu Name</label>
                        <input
                            type='text'
                            id="nameItems"
                            placeholder='Name Of Item'
                            className='form-control'
                            required
                            ref={name}
                            onChange={hideAlert}
                        />
                    </div>
                   
                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={showAddBoxFun}
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddMenu