import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const ChartCircle = (
  {
    chartLabel,
    nameOfChart,
    NumberOfTotal,
    colorOfTotal,
  }
) => {
  return (
    <div >
      <Doughnut data={
        {
          labels: chartLabel,
          datasets: [{
            label: nameOfChart,
            data: NumberOfTotal,
            backgroundColor: colorOfTotal,
            borderColor: [
              'gray'
            ],
            borderWidth: 1
          }],

        }
      }
        options={{
          plugins: {
            legend: {
              display: false,
            }
          }
        }}
        
      />
    </div>
  )
}

export default ChartCircle