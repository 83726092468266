import "./App.css";
import Routes from "./components/Routes";
import SessionProvider from "./components/session/SessionProvider";
import { Route, Routes as Rou } from "react-router-dom";
import ForgetPassword from "./components/forget_verfication_new_Password/ForgetPassword";
import VerfiyCode from "./components/forget_verfication_new_Password/VerfiyCode";
import Login from "./components/login/Login";
// import Layout from './layout/Layout';
function App() {
  return (
    <>
      <SessionProvider>
        <Routes />
      </SessionProvider>
    </>
  );
}

export default App;
