import { useState } from "react"
import { fetchUrl } from "../../../functions/fetchUrl";
import WithShowAddEditDeleteOfAllPages from "../../HOC/WithShowAddEditDeleteOfAllPages";
import Admin from './admin/Admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import './createAccount.css'
const CreateAccount = ({

}) => {
    const [showOpenEyeIcon, setShowOpenEyeIcon] = useState(false)
    const [showOpenEyeIconConfirm, setShowOpenEyeIconConfirm] = useState(false)
    const [re_render, setRe_render] = useState(false)
    const [userExist, setUserExist] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [state, setState] = useState({
        email: '',
        username: '',
        mobileNumber: '1236987',
        password: '',
        confirmPassword: '',
        roleId: 1
    })
    const [confirmationPassword, setConfirmationPassword] = useState(true)
    const updateState = (nextSession) => {
        setState((prevSession) => ({
            ...prevSession,
            ...nextSession,
        }));
    }

    const handleChange = (e) => {
        setUserExist(false)
        let { name, value } = e.target;
        updateState({ [name]: value })
    }
    const handleChangeRadio = (e) => {
        let { name, value } = e.target;

        updateState({ [name]: parseInt(value) })
    }
    const handleCreateAdmin = async (e) => {
        e.preventDefault();
        let { email, username, password, confirmPassword, roleId, mobileNumber } = state;
        let reqBody = { email, username, password, roleId, mobileNumber }
        if (password == confirmPassword) {
            const res = await fetchUrl({ apiName: 'admin/signup', reqBody, method: 'post' })
            // console.log(res.err === 'userExist')
            if (res.err === 'userExist') {
                setUserExist(true)
                return
            }

            // res.token ? alert('created success') : alert('something error...');
            if (res.token) {
                // alert('created success')
                setSuccess(true)
                setRe_render(!re_render)
            } else {
                // alert('something error...')
                setError(true)
            }
            updateState({})
        } else {
            setConfirmationPassword(false)
        }
    }
    const handleClickIconEye = () => {
        const input = document.querySelector('#password')
        if (input.type === 'password') {
            setShowOpenEyeIcon(true)
            input.type = 'text'
        } else {
            setShowOpenEyeIcon(false)
            input.type = 'password'
        }
    }
    const handleClickIconEyeConfirm = () => {
        const confirmPassword = document.querySelector('#confirmPassword')
        if (confirmPassword.type === 'password') {
            setShowOpenEyeIconConfirm(true)
            confirmPassword.type = 'text'
        } else {
            setShowOpenEyeIconConfirm(false)
            confirmPassword.type = 'password'
        }
    }
    return (
        <div className='col-10' style={{
            position: 'relative',
            left: '250px',
            top: '100px',
          }}>
            <div className="row">

                <div className="col-12">
                    {
                        success && <div className="alert alert-success" role="alert">
                            Created Successfully...
                            {
                                setTimeout(() => {
                                    setError(false)
                                    setSuccess(false)
                                    setRe_render(!re_render)
                                }, 1500)
                            }
                        </div>
                    }
                    {
                        error && <div className="alert alert-danger" role="alert">
                            Created Failed, Try Again...
                            {
                                setTimeout(() => {
                                    setError(false)
                                    setSuccess(false)
                                }, 1500)
                            }
                        </div>
                    }
                    <h2 className="text-secondary font-weight-bold" >Create Account</h2>
                    <div className='ml-5'>
                        <form className='w-75' onSubmit={handleCreateAdmin}>
                            <div className='mt-2'>
                                <label htmlFor="email">Email</label>
                                <input
                                    type='email'
                                    id="email"
                                    placeholder='Example@some.com'
                                    name='email'
                                    className='form-control'
                                    required
                                    onChange={handleChange}
                                />
                                {
                                    userExist && <p className="text-danger">
                                        email Already Exist
                                    </p>
                                }
                            </div>
                            <div className='mt-2'>
                                <label htmlFor="email">Username</label>
                                <input
                                    type='text'
                                    id="username"
                                    placeholder='Mobimartech app'
                                    name='username'
                                    className='form-control'
                                    required
                                    onChange={handleChange}
                                />
                                {/* {
                                    userExist && <p className="text-danger">
                                        email Already Exist
                                    </p>
                                } */}
                            </div>
                            <div className='mt-2'>
                                <label htmlFor="password">Password</label>
                                <FontAwesomeIcon
                                    icon={
                                        showOpenEyeIcon ? faEye : faEyeSlash
                                    }
                                    className='icon-eye'
                                    onClick={handleClickIconEye}
                                />
                                <input
                                    type='password'
                                    id="password"
                                    name='password'
                                    placeholder='Password'
                                    className='form-control'
                                    required
                                    onChange={(e) => { handleChange(e); setConfirmationPassword(true) }}
                                />
                            </div>
                            <div className='mt-2'>
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <FontAwesomeIcon
                                    icon={
                                        showOpenEyeIconConfirm ? faEye : faEyeSlash
                                    }
                                    className='icon-eye-confirm'
                                    onClick={handleClickIconEyeConfirm}
                                />
                                <input
                                    type='password'
                                    id="confirmPassword"
                                    name='confirmPassword'
                                    placeholder='Confirm Password'
                                    className='form-control'
                                    required
                                    onChange={(e) => { handleChange(e); setConfirmationPassword(true) }}
                                />
                                {
                                    !confirmationPassword ? <p style={{ color: 'red' }}>passwords does not matching</p> : null
                                }
                            </div>
                            {/* <div className='mt-2'>
                                <label htmlFor="mobileNumber">Mobile Number</label>
                                <input
                                    type='number'
                                    id="mobileNumber"
                                    name='mobileNumber'
                                    placeholder='Password'
                                    className='form-control'
                                    required
                                    onChange={(e) => { handleChange(e)}}
                                />
                            </div> */}
                            <fieldset className='shadow mt-2'>
                                <legend><small>This account for?</small></legend>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            id="superAdmin"
                                            name="roleId"
                                            className="custom-control-input"
                                            value={2}
                                            required
                                            onChange={handleChangeRadio}
                                        />
                                        <label className="custom-control-label" htmlFor="superAdmin">Super Admin</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            id="admin"
                                            name="roleId"
                                            className="custom-control-input"
                                            value={1}
                                            defaultChecked
                                            required
                                            onChange={handleChangeRadio}

                                        />
                                        <label className="custom-control-label" htmlFor="admin">Admin</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            id="accountant"
                                            name="roleId"
                                            className="custom-control-input"
                                            value={3}
                                            required
                                            onChange={handleChangeRadio}
                                        />
                                        <label className="custom-control-label" htmlFor="accountant">Accountant</label>
                                    </div>
                                </div>
                            </fieldset>
                            <button
                                type="submit"
                                className="btn btn-primary mt-3">
                                Create Account
                            </button>
                        </form>
                    </div>
                </div>

            </div>
            <div className="row">
                <Admin re_render={re_render} />
            </div>
        </div>
    )
}

export default CreateAccount