import "./subwidget.css";
import { useState, useEffect } from "react";

import AllServiceLogs from "./AllServicesAdv";
import AllPosted from "./AllPosted";
import AllMTSUB from "./AllMTSUB";

const AllLogs = ({ table, role }) => {
  return (
    <div
      className="col-11 "
      style={{
        position: "relative",
        left: "200px",
        top: "80px",
      }}
    >
      <div>
        <div className="row  p-3"></div>
        {role !== "accountant" && (
          <div className="row">
            <div className="col-11 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">Advertisment</h2>
              <div>
                <AllServiceLogs table={table + "/alladverlog"} />
              </div>
            </div>
          </div>
        )}

        <div className="row  p-3"></div>
        {role !== "accountant" && (
          <div className="row">
            <div className="col-11 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">Pixels</h2>
              <div>
                <AllPosted table={table + "/allpostedlog"} />
              </div>
            </div>
          </div>
        )}

        {/* <div className="row  p-3"></div>
        {role === "superadmin" && (
          <div className="row">
            <div className="col-11 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">MTSUBLOG</h2>
              <div>
                <AllMTSUB table={table + "/mtsublog"} />
              </div>
            </div>
          </div>
        )} */}

        {/* End */}
      </div>
    </div>
  );
};

export default AllLogs;
