import React from 'react'

const EditAdmin = ({
    showEditingAdminBox,
    data
}) => {
    return (
        <div id="addNoteModal" className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Edit Admin <span className='text-warning'>{data.username}</span></h2>
                </div>
                <div>
                    {/* {
            success && <div className="alert alert-success" role="alert">
                Created Successfully...
            </div>
        }
        {
            error && <div className="alert alert-anger" role="alert">
                Created Failed Try Again...
            </div>
        } */}
                </div>
                <form className='w-100 p-5' encType="multipart/form-data">
                    <div className='mt-2'>
                        <label htmlFor="username">Username</label>
                        <input
                            type='text'
                            id="username"
                            placeholder='Username'
                            className='form-control'
                            required
                            defaultValue={data.username}
                        />
                    </div>
                    <div className='mt-2'>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <input
                            type='text'
                            id="mobileNumber"
                            placeholder='Mobile Number'
                            className='form-control'
                            required
                            defaultValue={data.mobileNumber}
                        />
                    </div>
                    {/* <div className='mt-2'>
            <label htmlFor="image">Image</label>
            <input
                type='file'
                id="image"
                className='form-control'
                required
            />
        </div> */}

                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={showEditingAdminBox}
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default EditAdmin