import { useRef, useState } from 'react'
import { Navigate } from 'react-router-dom'
import logo from './../../images/mmt.png'
import NewPassword from './NewPassword'

const VerfiyCode = ({
  data,
  code
}) => {
  const codeField = useRef()

  const [showResetPasswordBox, setShowResetPasswordBox] = useState(false)
  const [alert, setAlert] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code === codeField.current.value) {
      setShowResetPasswordBox(true)
    } else {
      setAlert(true)
    }
  }
  return (
    <>{
      showResetPasswordBox ? <NewPassword data={data.result[0]}/> : (

        <div id="login-form-wrap">
          <div className='d-flex justify-content-center p-3'>
            <img className='logo' src={logo} />
          </div>
          {
            alert && <div className="alert alert-danger" role="alert">
              It Is Note Matched With Code We Sent, Try Again ...!
            </div>
          }
          <div>
            <p>Please Enter Your Code That You Received By Your Email</p>
          </div>
          <form
            id="login-form"
            onSubmit={handleSubmit}
          >
            <div>
              <input
                type="number"
                id="code"
                name="code"
                placeholder="EX: 098764532"
                ref={codeField}
                maxLength={10}
                required
              />
            </div>
            <div>
              <button
                className='mb-5 login-button'
                type="submit"
                id="login"
              // onClick={handleClickBtn}
              > Submit
              </button>
            </div>
          </form>
        </div>
      )
    }

    </>
  )
}

export default VerfiyCode