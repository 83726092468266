import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import Layout from "../layout/Layout";
import ForgetPassword from "./forget_verfication_new_Password/ForgetPassword";
import NewPassword from "./forget_verfication_new_Password/NewPassword";
// import SuperAdminLayout from "../layout/SuperAdminLayout";
import Login from "./login/Login";
import Home from "./pages/home/Home";
import SessionContext from "./session/SessionContext";
// import SuperAdmin from "./super_admin/SuperAdmin";

export default function Routes(props) {
  const {
    role,
    Username,
    imageUser,
    session: {
      user: { token },
    },
  } = useContext(SessionContext);
  // console.log('Route Role',role);
  // console.log('Route User',Username);
  return (
    <div>
      {/* {console.log("token",token)} */}
      <Switch>
        {/* <Route path="/" element={
            (token && role === 'superadmin' || role === 'admin' || role === 'accountant') ?
            <Navigate to={`/${role}/*`} replace /> :
            
            <Login />
          
          }*/}
        <Route path="forget-password" element={<ForgetPassword />} />

        {/* {console.log("role", role)} */}
        <Route
          path="/"
          element={
            (token && role === "superadmin") ||
            role === "admin" ||
            role === "accountant" ? (
              <Navigate to={`/${role}/*`} replace />
            ) : (
              <Navigate to={`/login`} replace />
            )
          }
        />

        <Route
          path="/login"
          element={
            <UnRequireAuth token={token} role={role}>
              {/* {console.log('token',token)} */}
              <Login />
            </UnRequireAuth>
          }
        />
        <Route
          path={`${role}/*`}
          element={
            <RequireAuth token={token} role={role && role}>
              {/* {console.log('seccccccccond')} */}
              <Layout role={role} Username={Username} imageUser={imageUser} />
            </RequireAuth>
          }
        />
      </Switch>
    </div>
  );
}

function UnRequireAuth({ children, token, role }) {
  // console.log(role)
  let location = useLocation();
  if (token && role === "superadmin") {
    return (
      <Navigate
        to={`/superadmin/bnfootball`}
        state={{ from: location }}
        replace
      />
    );
  } else if (token && role === "admin") {
    return <Navigate to={`/admin/sheild`} state={{ from: location }} replace />;
  } else if (token && role === "accountant") {
    return (
      <Navigate
        to={`/accountant/islamnquran`}
        state={{ from: location }}
        replace
      />
    );
  } else {
    return children;
  }
}
function RequireAuth({ children, token, role }) {
  // console.log('RequireAuth',role)

  let location = useLocation();
  if (!token) {
    // console.log("logouttttttttttttt")
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
}

const Role = (role) => {
  switch (role) {
    case "superadmin":
      return "/superadmin/home";

    case "admin":
      return "/admin/home";

    case "accountant":
      return "/accountant/home";

    default:
      break;
  }
};
